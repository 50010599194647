//@ts-nocheck
import { AnyAction, createSlice, ThunkDispatch } from "@reduxjs/toolkit";
import _ from "lodash";
import { HiInformationCircle } from "react-icons/hi";
import { toast } from "react-toastify";
import { RootState } from "../app/reducer";
import { createMessage } from "../components/message";
import { createIcon } from "../components/icon-with-color";
import { getIntl } from "../locale";
import { oauth, oauthBlob } from "../util/api";
import qs from "querystring";
import { groupList, ITEM_CHANGE_ACTION_CHECK_PRICES, sortList } from "../pages/liveorder/helper";
import { downloadCSV } from "../util/helper/csv";
import { FILTER_EXPORT_TYPE_MAPPING, FILTER_KEYS } from "../pages/order-history/helper-order-list";
import helper from "../util/helper";
import orderHistoryActions from "./order-history";
import { BiError } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import { downloadXLSX } from "util/helper/download-xlsx";
import config from "config";

function emptyFunction() {
    // does nothing
}

export const ERROR_MAP: { [key: string]: string } = {
    "474": "over_refund",
    "487": "partial_request_not_allowed",
    "489": "refund_not_settled",
    "490": "refund_not_settled",
    "492": "braintree_error_dialog",
};

export const itemEditDialogState = {
    itemEditDialogSearchInput: "",
};

const initialState: any = {
    ...itemEditDialogState,
    liveOrderList: [],
    isListLoading: false,
    listStyle: "list",
    filteredKeyword: "",
    groupedLiveOrderList: {},
    modelController: "", //an controller to open/close all model of each order, consist of `${orderId}:${modelname}',
    groupByTable: true, //an controller to group orders by table
    products: [],
    groupPurchaseList: [],
    cachedProducts: {},
};

const slice = createSlice({
    name: "liveOrder",
    initialState,
    reducers: {
        setState(state, { payload }) {
            state = Object.assign(state, payload);
        },
        getLiveOrdersListSuccess(state, { payload }) {
            state.liveOrderList = payload;
            state.groupedLiveOrderList = groupList(payload, state);

            if (payload?.[0]) {
                state.activeId = payload?.[0]?.ord_id;
                state.expansionControllers = {
                    ...state.expansionControllers,
                    [payload?.[0]?.ord_id]: { showDetail: true },
                };
            }
        },
        getLiveOrdersPartSuccess(state, { payload }) {
            state.liveOrderList = state.liveOrderList
                .map((lo: any) => {
                    const updatedLiveOrder = payload?.records?.find?.((ulo: any) => ulo?.ord_id === lo?.ord_id);
                    return !_.isEmpty(updatedLiveOrder) ? updatedLiveOrder : lo;
                })
                .filter((lo: any) => {
                    const updatedIds = payload?.orderIds?.includes(lo?.ord_id);
                    const returnedEmpty = _.isEmpty(payload?.records?.find((ulo: any) => ulo?.ord_id === lo?.ord_id));
                    const outdatedOrder = payload?.outdatedId === lo?.ord_id;
                    return !outdatedOrder && !(updatedIds && returnedEmpty);
                });

            payload?.records?.forEach?.((ulo: any) => {
                if (_.isEmpty(state.liveOrderList?.find?.((lo: any) => lo?.ord_id === ulo?.ord_id))) {
                    state.liveOrderList?.push(ulo);
                    state.expansionControllers = {
                        ...state.expansionControllers,
                        [ulo.ord_id]: { showDetail: true },
                    };
                    if (payload?.updateFocus) state.activeId = ulo?.ord_id;
                }
            });
            const sortedList = sortList(state.liveOrderList);
            state.groupedLiveOrderList = groupList(sortedList, state);
        },
        updateStyle(state, { payload }) {
            state.listStyle = payload?.style;
        },
        suggestionCheckPricesSucess(state, { payload }) {
            const response = _.get(payload, "response.res.old_order", {});
            const orderId = _.get(payload, "payload.order_id", "");
            if (orderId) state.newPrices[orderId] = response;
        },
        getProductsSuccess(state, { payload }) {
            const pagination = _.get(payload, "response.paging", {});
            const records = Array.isArray(_.get(payload, "response.records", []))
                ? _.get(payload, "response.records", [])
                : [];
            let conditions = _.get(payload, "conditions", {});
            if (conditions?.reset) {
                state.products = records;
            } else {
                state.products = _.unionWith(state.products, records, _.isEqual);
            }

            conditions = _.omit(conditions, ["reset"]);
            state.pagination = pagination;
            state.conditions = conditions;

            // add product records to cached products
            const cids = conditions?.cids;
            const lan = conditions?.lan;
            const keywords = conditions?.keywords;
            if (shouldCheckCachedProducts(cids, lan, keywords)) {
                const updatedCachedProducts = _.cloneDeep(state.cachedProducts);
                _.set(updatedCachedProducts, [cids, lan], _.cloneDeep(records));
                state.cachedProducts = updatedCachedProducts;
            }
        },
        addProducts: (state, { payload }) => {
            state.products = _.unionWith(state.products, payload?.records, _.isEqual);
        },
        getGroupPurchaseListPagingSuccess(state, { payload }) {
            payload.forEach((product: any) => {
                state.groupPurchaseList.push(product);
            });
        },
    },
});

export const liveOrder = slice.reducer;

const shouldCheckCachedProducts = (cids: any, lan: any, keywords: any) => {
    return !_.isNil(cids) && !_.isNil(lan) && _.isNil(keywords);
};

const actions: any = _.cloneDeep(slice.actions);

actions.getLiveOrdersList = () => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    dispatch(actions.setState({ isListLoading: true }));
    try {
        const p = {
            method: "GET",
        };

        const r = await oauth("LIVE_ORDER")(p);

        switch (r.RC) {
            case 200:
                dispatch(actions.getLiveOrdersListSuccess(r.records ?? []));
                break;
            default:
                break;
        }
    } catch (e) {
        // Handle error
    }
    dispatch(actions.setState({ isListLoading: false }));
};

actions.updateLiveOrderDeliveryStatus =
    (payload: any) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
        const intl = getIntl();
        dispatch(actions.setState({ isListLoading: true }));
        try {
            const p = {
                method: "POST",
                body: JSON.stringify({ data: _.omit(_.cloneDeep(payload), ["order", "orderStore"]) }),
            };

            const r = await oauth("ORDER_DELIVERY_STATUS")(p);

            const callBack = _.get(payload, "callBack", emptyFunction);
            callBack(r);
            const order_ids = [payload?.order_id];
            switch (r.RC) {
                case 200:
                    toast(createMessage(intl.formatMessage({ id: "order_status_updated" }), HiInformationCircle));
                    dispatch(
                        actions.getLiveOrdersPartSuccess({
                            records: [r?.order] ?? [],
                            orderIds: order_ids,
                            updateFocus: _.get(payload, "updateFocus", true),
                            outdatedId: payload?.outdatedId,
                        })
                    );
                    dispatch(orderHistoryActions.updateOrderDetail({ response: r }));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
        dispatch(actions.setState({ isListLoading: false }));
    };

actions.getLiveOrdersPart = (payload: any) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    dispatch(actions.setState({ isListLoading: true }));
    try {
        const p = {
            method: "POST",
            body: JSON.stringify({ data: payload }),
        };

        const r = await oauth("LIVE_ORDER")(p);

        switch (r.RC) {
            case 200: {
                const order_ids = [payload?.order_id];
                if (r?.res?.ord_id) {
                    order_ids.push(r?.res?.ord_id);
                }

                dispatch(
                    actions.getLiveOrdersPartSuccess({
                        records: r?.records ?? [],
                        orderIds: payload?.order_ids,
                        updateFocus: _.get(payload, "updateFocus", true),
                        outdatedId: payload?.outdatedId,
                    })
                );
                dispatch(orderHistoryActions.updateOrderDetail({ response: r }));
                break;
            }
            default:
                break;
        }
    } catch (e) {
        // Handle error
    }
    dispatch(actions.setState({ isListLoading: false }));
};

actions.updateOrderComment = (payload: any) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    const intl = getIntl();
    dispatch(actions.setState({ isListLoading: true }));
    try {
        const p = {
            method: "POST",
            body: JSON.stringify(payload),
        };

        const r = await oauth("ORDER_COMMENTS")(p);

        switch (r.RC) {
            case 200:
                toast(createMessage(intl.formatMessage({ id: "added_order_comment" }), HiInformationCircle));
                dispatch(actions.getLiveOrdersPart({ order_ids: [payload?.order_id] }));
                dispatch(orderHistoryActions.fetchOrderComments({ response: r }));
                break;
            default:
                break;
        }
    } catch (e) {
        // Handle error
    }
    dispatch(actions.setState({ isListLoading: false }));
};

actions.getProductDetail = (payload: any) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    dispatch(actions.setState({ isGettingProductDetail: false }));
    try {
        const query = qs.stringify(payload);
        const p = {
            method: "GET",
        };
        const r = await oauth("LIVE_ORDER_PRODUCT", query)(p);
        switch (r.RC) {
            case 200:
                dispatch(
                    actions.setState({
                        productDetail: r?.records?.product,
                    })
                );
                break;
            default:
                break;
        }
    } catch (e) {
        // Handle error
    }
    dispatch(actions.setState({ isGettingProductDetail: false }));
};

actions.getProducts = (payload: any) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    dispatch(actions.setState({ isGettingProducts: true }));
    try {
        const p = {
            method: "GET",
        };

        dispatch(
            actions.setState({
                lastSearch: payload,
            })
        );

        const shouldReset = payload?.reset;
        if (shouldReset) {
            dispatch(actions.setState({ products: [] }));
        }

        const cachedProducts = payload?.cachedProducts;
        const cids = payload?.cids;
        const lan = payload?.lan;
        const keywords = payload?.keywords;
        // check that the products are already in the cached products
        if (shouldCheckCachedProducts(cids, lan, keywords) && _.has(cachedProducts, [cids, lan])) {
            dispatch(
                actions.addProducts({
                    records: _.cloneDeep(_.get(cachedProducts, [cids, lan])),
                })
            );
        } else {
            let payloadClone = _.cloneDeep(payload);
            payloadClone = _.omit(payloadClone, "reset");
            payloadClone = _.omit(payloadClone, "cachedProducts");
            const r = await oauth("LIVE_ORDER_PRODUCTS", qs.stringify(payloadClone))(p);
            switch (r.RC) {
                case 200:
                    dispatch(
                        actions.getProductsSuccess({
                            conditions: payload,
                            response: r,
                        })
                    );
                    break;
                default:
                    break;
            }
        }
    } catch (e) {
        // Handle error
    }
    dispatch(actions.setState({ isGettingProducts: false }));
};

actions.updateSuggestions = (payload: any) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    const intl = getIntl();
    dispatch(actions.setState({ isListLoading: true }));
    try {
        const p = {
            method: "POST",
            body: JSON.stringify({ data: payload }),
        };

        const r = await oauth("LIVE_ORDER_UPDATE_SUGGESTIONS")(p);

        switch (r.RC) {
            case 200: {
                const order_ids = [payload?.order_id];
                const newOrderId =
                    _.get(r, "records.new_order.ord_id") ||
                    _.get(r, "records.old_order.ord_id") ||
                    _.get(r, "res.new_order.ord_id") ||
                    _.get(r, "res.old_order.ord_id") ||
                    _.get(r, "order.ord_id") ||
                    _.get(r, "records.ord_id") ||
                    _.get(r, "res.ord_id");
                if (newOrderId) {
                    order_ids.push(newOrderId);
                }
                const checkPrice = payload?.action === ITEM_CHANGE_ACTION_CHECK_PRICES;
                if (checkPrice) {
                    dispatch(actions.suggestionCheckPricesSucess({ response: r, payload }));
                } else {
                    toast(createMessage(intl.formatMessage({ id: "update_suggestions_success" }), HiInformationCircle));
                    dispatch(actions.getLiveOrdersPart({ order_ids }));
                    dispatch(orderHistoryActions.updateOrderDetail({ response: r }));
                }
                break;
            }
            default:
                break;
        }

    } catch (e) {
        // Handle error
    }
    dispatch(actions.setState({ isListLoading: false, onEdit:false}));
};

actions.updateOrder = (payload: any) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    const intl = getIntl();
    dispatch(actions.setState({ isListLoading: true }));
    try {
        const p = {
            method: "POST",
            body: JSON.stringify({
                data: { ..._.omit(_.cloneDeep(payload?.data), ["order", "orderStore"]), response_format: 2 },
            }), //2 returns as live order
        };

        const r = await oauth("UPDATE_ORDER")(p);

        if (r?.RC === 200) {
            const order_ids = [payload?.data?.order_id];
            const newOrderId =
                _.get(r, "records.new_order.ord_id") ||
                _.get(r, "records.old_order.ord_id") ||
                _.get(r, "res.new_order.ord_id") ||
                _.get(r, "res.old_order.ord_id") ||
                _.get(r, "order.ord_id") ||
                _.get(r, "records.ord_id") ||
                _.get(r, "res.ord_id");
            if (newOrderId) {
                order_ids.push(newOrderId);
            }

            // update live order redux state with new updated order
            dispatch(
                actions.getLiveOrdersPartSuccess({
                    records: [r?.records?.old_order] ?? [],
                    orderIds: order_ids,
                    updateFocus: _.get(payload, "updateFocus", true),
                    outdatedId: payload?.outdatedId,
                })
            );
            dispatch(orderHistoryActions.updateOrderDetail({ response: r }));
        }else{
            toast(createMessage(intl.formatMessage({ id: "failed_to_refund" }), HiInformationCircle));
            dispatch(orderHistoryActions.setState({ allowRefundSubmit: true }))
        }

        const callBack = _.get(payload, "data.callBack", emptyFunction);
        callBack(r);

        //normal message handling
        const showMessage = _.get(payload, "data.withMessage", true);
        if (showMessage) {
            switch (r?.RC) {
                case 200:
                    toast(createMessage(intl.formatMessage({ id: "update_order_success" }), HiInformationCircle));
                    break;
                case 400:
                    if (r?.msg === "Tracking code+number already exists") {
                        toast(
                            createMessage(
                                intl.formatMessage({ id: "duplicate_order_tracking_message" }),
                                BiError,
                                "white",
                                "white"
                            ),
                            {
                                autoClose: false,
                                closeOnClick: true,
                                style: {
                                    backgroundColor: "#e74c3c",
                                    color: "white !important",
                                },
                                closeButton: createIcon(MdClose, "white"),
                            }
                        );
                    }
                    break;
                case 492:
                    dispatch(
                        actions.setState({
                            dialog: "braintree-payment-error",
                            dialogOrder: payload?.data?.order ?? {},
                            dialogOrderStore: payload?.data?.orderStore ?? {},
                        })
                    );
                    break;
                default:
                    toast(
                        createMessage(
                            intl.formatMessage({
                                id: ERROR_MAP[String(r?.RC)] ?? "unexpect_error",
                            }),
                            BiError,
                            "white",
                            "white"
                        ),
                        {
                            autoClose: false,
                            closeOnClick: true,
                            style: {
                                backgroundColor: "#e74c3c",
                                color: "white !important",
                            },
                            closeButton: createIcon(MdClose, "white"),
                        }
                    );
                    break;
            }
        }
    } catch (e) {
        // Handle error
    }
    dispatch(actions.setState({ isListLoading: false }));
};

actions.getGroupPurchaseList = (params?: any) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
        const response = await oauth(
            "GROUP_PURCHASE_LIST",
            qs.stringify(params)
        )({
            method: "GET",
        });

        switch (response.RC) {
            case 200:
                if (params.page > 1) {
                    dispatch(actions.getGroupPurchaseListPagingSuccess(response.res));
                    dispatch(actions.setState({ groupPurchaseListPaging: response.paging }));
                } else {
                    dispatch(
                        actions.setState({ groupPurchaseList: response.res, groupPurchaseListPaging: response.paging })
                    );
                }
                break;
            default:
                break;
        }
    } catch (e) {
        // Handle error
    }
};

actions.exportOrders = (payload: any) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    dispatch(actions.setState({ isListLoading: true }));
    try {
        const p = {
            method: "POST",
            body: JSON.stringify(payload),
        };

        const r = await oauthBlob("EXPORT_ORDER")(p);
        if (r) {
            const exportType = _.get(payload, `data.${FILTER_KEYS.export_type}`);
            const fileName = () => {
                const typeName = helper.getKeyByValue(FILTER_EXPORT_TYPE_MAPPING, exportType);
                const reformattedTypeName = typeName?.replaceAll("_", "-");
                return `export-${reformattedTypeName}`;
            };
            if (exportType === FILTER_EXPORT_TYPE_MAPPING.all_in_one_report_excel) {
                downloadXLSX({ blob: r, filename: fileName() });
            } else {
                downloadCSV({ blob: r, filename: fileName() });
            }
        }
    } catch (e) {
        // Handle error
    }
    dispatch(actions.setState({ isListLoading: false }));
};

actions.mergeOrders = (payload: any) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    const intl = getIntl();
    dispatch(actions.setState({ isListLoading: true }));
    try {
        const p = {
            method: "POST",
            body: JSON.stringify({ data: { ...payload?.data, response_format: 2 } }), //2 returns as live order
        };

        const r = await oauth("MERGE_ORDERS")(p);

        if (r?.RC === 200) {
            const order_ids = _.cloneDeep(payload?.data?.order_ids);
            const newOrderId = r?.res?.new_order?.ord_id;
            if (newOrderId) {
                order_ids.push(newOrderId);
            }
            dispatch(actions.getLiveOrdersPart({ order_ids }));
            dispatch(orderHistoryActions.updateOrderDetail({ response: r }));
        }

        const callBack = _.get(payload, "data.callBack", emptyFunction);
        callBack(r);

        //normal message handling
        const showMessage = _.get(payload, "data.withMessage", true);
        if (showMessage) {
            switch (r?.RC) {
                case 200:
                    toast(createMessage(intl.formatMessage({ id: "update_order_success" }), HiInformationCircle));
                    break;
                default:
                    toast(
                        createMessage(
                            intl.formatMessage({
                                id: ERROR_MAP[String(r?.RC)] ?? "unexpect_error",
                            }),
                            BiError,
                            "white",
                            "white"
                        ),
                        {
                            autoClose: false,
                            closeOnClick: true,
                            style: {
                                backgroundColor: "#e74c3c",
                                color: "white !important",
                            },
                            closeButton: createIcon(MdClose, "white"),
                        }
                    );
                    break;
            }
        }
    } catch (e) {
        // Handle error
    }
    dispatch(actions.setState({ isListLoading: false }));
};

actions.markAsPaid = (orderId: string) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    dispatch(
        actions.updateOrder({
            data: {
                order_id: orderId,
                action: config.UPDATE_ORDER_API_ACTION_MAPPING.paid,
            },
        })
    );
};

actions.markAsUnPaid = (orderId: string) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    dispatch(
        actions.updateOrder({
            data: {
                order_id: orderId,
                action: config.UPDATE_ORDER_API_ACTION_MAPPING.unpaid,
            },
        })
    );
};

export default actions;
