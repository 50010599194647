import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import _ from "lodash";
import { connect } from "react-redux";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import Collapse from "@material-ui/core/Collapse";
import "./order-detail-grouped-order-list-info.scss";
import storeHelper, { isGroupedOrder } from "../../helper";
import {
    formatOrderId,
    getPaymentStringWithCreditCardInfo,
    getShortDeliveryStatus,
} from "../../../order-history/helper";
import { getCustomerName, getOrderId, getOrderTotalStr } from "../../../order-history/helper-order-list";
import config from "../../../../config";
import _actions from "../../../../slices/live-order";

class OrderDetailOrderListInfo extends Component {
    state = { in: true };

    getStore = (order) => {
        const orderGid = _.get(order, "g_id", "");
        const storesData = _.get(this.props, "storesData", []);
        return storeHelper.getStoreWithGid(orderGid, storesData);
    };

    showSection = () => {
        return isGroupedOrder(this.props.order) && _.get(this.props, "state.groupedByItem");
    };

    showDetail = () => this.state.in;

    renderTitle = () => {
        return (
            <div className="section-title" onClick={() => this.setState({ in: !this.showDetail() })}>
                <div className="section-title-text">
                    <FormattedMessage id="order_list" />
                </div>
                <div>{this.showDetail() ? <DownOutlined /> : <RightOutlined />}</div>
            </div>
        );
    };

    renderOrdersInfo = () => {
        const orders = Array.isArray(_.get(this.props, "order.orders")) ? _.get(this.props, "order.orders") : [];
        return <Collapse in={this.showDetail()}>{orders.map((order) => this.renderOrderInfo(order))}</Collapse>;
    };

    renderOrderInfo = (order) => {
        const renderLeftInfo = () => {
            return (
                <div className="order-detail-grouped-order-order-item-left-info">
                    <span>#{formatOrderId(getOrderId(order))}</span>
                    <span>&nbsp;·&nbsp;</span>
                    <span>{getOrderTotalStr(order, this.getStore(order))} </span>
                    <span>&nbsp;·&nbsp;</span>
                    <span>{getPaymentStringWithCreditCardInfo(order)} </span>
                    <span>&nbsp;·&nbsp;</span>
                    <span>{getCustomerName(order)}</span>
                </div>
            );
        };

        const renderRightInfo = () => {
            const status = getShortDeliveryStatus(order, this.getStore(order));
            return (
                <div
                    style={{
                        color: `#${config.ORDER_STATUS_COLOR_MAPPING[status]}`,
                    }}
                    className="order-detail-grouped-order-order-item-right-info"
                >
                    <FormattedMessage id={`status_${status}` || " "} />
                </div>
            );
        };
        return (
            <div className="order-detail-grouped-order-order-item">
                {renderLeftInfo()}
                {renderRightInfo()}
            </div>
        );
    };

    render() {
        return (
            <div className="order-detail-grouped-order-list-info">
                {this.showSection() ? (
                    <div className="order-detail-grouped-order-list-info-content">
                        {this.renderTitle()}
                        {this.renderOrdersInfo()}
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    state: _.get(state, "liveOrders", {}),
    storesData: _.get(state, "managedStores.activeStores", []),
});

const mapDispatchToProps = {
    setState: _actions.setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderDetailOrderListInfo));
