import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Tag } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import "./order-list-applied-filters.scss";
import {
    sortFilterSearchValues,
    KEY_TYPE_MAPPING,
    getOptionsWithType,
    FILTER_KEYS,
    getAllOptionsValue,
    getFilterDayLabel,
} from "../../helper-order-list";
import moment from "moment";
import actions from "../../../../slices/order-history";
import helper from "../../../../util/helper";

const HIDDEN_KEYS = ["paging", FILTER_KEYS.export_type, "page", "limit", FILTER_KEYS.return_type];
const SHOW_INDICATOR_KEYS = [FILTER_KEYS.customer_id];
const GROUP_KEYS = [
    [FILTER_KEYS.start_date, FILTER_KEYS.end_date],
    [FILTER_KEYS.min_amount, FILTER_KEYS.max_amount],
];

class AppliedFilter extends Component {
    getAllFilters = () => {
        const allFilters = _.cloneDeep(_.get(this.props, "state.searchConditions.search_condition", {}));
        const sortedFilter = sortFilterSearchValues(allFilters);
        const filteredKey = {};
        Object.keys(sortedFilter).forEach((key) => {
            const value = sortedFilter[key];
            if (HIDDEN_KEYS.includes(key)) {
                return;
            }
            if (!value) {
                return;
            }
            if (helper.isFunction(value)) {
                return;
            }
            const groupKeys = Array.prototype.concat.apply([], GROUP_KEYS);
            if (groupKeys.includes(key)) {
                const group = GROUP_KEYS.find((g) => g.includes(key));
                const mainKey = _.get(group, 0);
                filteredKey[mainKey] = { ...filteredKey[mainKey], ...{ [key]: value } };
                return;
            }

            filteredKey[key] = value;
        });
        return filteredKey;
    };

    renderAllTags = () => {
        const fitlers = this.getAllFilters();

        return Object.keys(fitlers).map((status, key) => {
            const value = fitlers[status];
            if (Object.keys(KEY_TYPE_MAPPING).includes(status)) {
                return this.renderWithOptionTag(status, value);
            } else if (status === FILTER_KEYS.start_date) {
                return this.renderDateTag(status, value, key);
            } else if (SHOW_INDICATOR_KEYS.includes(status)) {
                return this.renderWithTextIndicatorTag(status, value);
            } else if (status === FILTER_KEYS.min_amount) {
                return this.renderMoneyTag(status, value, key);
            } else if (status === FILTER_KEYS.group_purchase_ids) {
                return null;
            } else {
                return this.renderNormalTag(status, value, key);
            }
        });
    };

    renderWithOptionTag = (status, values) => {
        const type = KEY_TYPE_MAPPING[status];
        const options = getOptionsWithType(type, this.props.state, this.props.storeRecords);
        const valuesArray = Array.isArray(values) ? values : [];
        return valuesArray.map((value, key) => {
            const option = options.find((option) => option.value === value);
            const label = option.label
                ? status === FILTER_KEYS.status
                    ? `category_${option.label}`
                    : option.label
                : " ";
            const content = (
                <span>
                    <FormattedMessage id={label} /> ({option.total})
                </span>
            );
            const handleClose = () => {
                const index = _.findIndex(values, (v) => v === value);
                values.splice(index, 1);
                if (_.isEmpty(values)) {
                    values = getAllOptionsValue(KEY_TYPE_MAPPING[status]);
                }
                this.props.fetchOrderList({ [status]: values });
            };

            return this.renderTag({ status, content, handleClose, key });
        });
    };

    renderDateTag = (status, value, key) => {
        const startDate = _.get(value, FILTER_KEYS.start_date);
        const endDate = _.get(value, FILTER_KEYS.end_date);
        const label = getFilterDayLabel(startDate, endDate);
        var content;
        if (!label) {
            const startDateMoment = moment(startDate, FILTER_KEYS.date_format);
            const endDateMoment = moment(endDate, FILTER_KEYS.date_format);
            const DISPALY_FORMAT = "YYYY/MM/DD";
            content = (
                <span>
                    {startDateMoment.format(DISPALY_FORMAT)}-{endDateMoment.format(DISPALY_FORMAT)}{" "}
                </span>
            );
        } else {
            content = (
                <span>
                    <FormattedMessage id={label ?? " "} />
                </span>
            );
        }
        const handleClose = () => {};
        return this.renderTag({ status, content, handleClose, closable: false, key });
    };

    renderWithTextIndicatorTag = (status, value, key) => {
        const content = (
            <span>
                <FormattedMessage id={status ?? " "} />: {String(value)}
            </span>
        );
        const handleClose = () => this.props.fetchOrderList({ [status]: "" });
        return this.renderTag({ status, content, handleClose, key });
    };

    renderMoneyTag = (status, value, key) => {
        const allKeys = Object.keys(value);
        const status2 = _.get(allKeys, 0) === status ? _.get(allKeys, 1) : _.get(allKeys, 0);
        const min = _.get(value, status) || 0;
        var content, handleClose;
        if (status2) {
            const max = _.get(value, status2);
            content = (
                <span>
                    ${min}-${max}
                </span>
            );
            handleClose = () => {
                this.props.fetchOrderList({ [status]: "", [status2]: "" });
            };
        } else {
            content = (
                <span>
                    <FormattedMessage id="min" />: ${min}{" "}
                </span>
            );
            handleClose = () => {
                this.props.fetchOrderList({ [status]: "" });
            };
        }
        return this.renderTag({ status, content, handleClose, key });
    };

    renderNormalTag = (status, value, key) => {
        const content = <span> {String(value)} </span>;
        const handleClose = () => this.props.fetchOrderList({ [status]: "" });
        return this.renderTag({ status, content, handleClose, key });
    };

    renderTag = (params) => {
        return (
            <Tag
                key={params.key}
                color={"blue"}
                closable
                onClose={(e) => {
                    e.preventDefault();
                    params.handleClose();
                }}
            >
                {params.content}
            </Tag>
        );
    };

    render() {
        return (
            <div id="order-list-applied-filters" className="order-list-applied-filters">
                {this.renderAllTags()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    state: _.get(state, "orders-page", {}),
    storeRecords: _.get(state, "store.records", {}),
});

const mapDispatchToProps = {
    fetchOrderList: actions.fetchOrderList,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AppliedFilter));
