import React, { Component } from "react";
import _helper from "../../../../util/helper";
import { Modal } from "antd";
import { Button, withStyles } from "@material-ui/core";
import "./_orderItems.scss";
import { FiMoreVertical } from "react-icons/fi";
import { injectIntl, FormattedMessage } from "react-intl";
import _ from "lodash";
import EditItemDialog from "./item-edit-dialog/index";
import EditItemDetailDialog from "./item-edit-dialog/item-detail-editor-dialog-main";
import {
    ITEM_CHANGE_ACTION_REMOVE,
    ITEM_CHANGE_ACTION_ADD,
    ITEM_CHANGE_ACTION_REPLACE,
    ITEM_CHANGE_ACTION_CANCEL_ALL,
    ITEM_CHANGE_ACTION_APPLIED,
    ITEM_CHANGE_ACTION_SUGGEST,
    isNoMoreItems,
} from "./helper";
import { connect } from "react-redux";
import ETADialog from "../processing-model";
import _actions from "../../../../slices/live-order";
import {
    formatOrderId,
    getItemOptionsArray,
    getPaymentStringWithCreditCardInfo,
    getShortDeliveryStatus,
} from "../../../order-history/helper";
import storeHelper, { isGroupedOrder, isRestaurant } from "../../helper";
import { getCustomerName, getOrderId, getOrderTotalStr } from "../../../order-history/helper-order-list";
import config from "../../../../config";
import Collapse from "@material-ui/core/Collapse";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import { HiExternalLink } from "react-icons/hi";
import { store } from "../../../../app/store";

const StyledButton = withStyles({
    root: {
        lineHeight: 1.3,
        fontSize: 17,
        paddingLeft: 5,
        paddingRight: 5,
        textTransform: "initial",
        borderColor: "#1890ff",
        color: "#1890ff",
        "&:hover": {
            color: "#016cd0",
            borderColor: "#016cd0",
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "none",
            color: "#016cd0",
            borderColor: "#016cd0",
        },
    },
})(Button);
const caretStyle = {
    color: "black",
};

class OrderItems extends Component {
    initialState = () => ({
        showItemEditDialog: false,
        itemChanges: [],
        item: {},
        renderingItem: {},
        itemChangeObject: {},
        onEdit: false,
    });

    state = this.initialState();

    componentDidMount() {
        this.checkModalOpen({});
    }

    componentDidUpdate(prevProps) {
        this.checkModalOpen(prevProps);
        if (_.get(prevProps, "order.ord_id") === this.getOrderId()) {
            return;
        } else {
            this.props.setState({
                newPrices: [],
            });
            this.setState(this.initialState());
        }
    }

    clearModalContoller = () =>
        this.props.setState({
            modelController: "",
        });

    getCurrentStyle = () => _.get(this.props, "listStyle", "list");

    getOrderId = () => {
        return _.get(this.props, "order.ord_id", "");
    };

    allowAddItem = () => {
        // "110": "pending_for_store_confirmation",
        // "112": "pending_for_customer_confirmation",
        // "115": "store_accepted",
        // "120": "store_is_preparing",
        const ALLOW_DELIVERY_STATUSES = ["110", "115", "112", "120", "135"];
        // "1": "pending",
        // "2": "processing",
        const ALLOW_ORDER_STATUSES = ["1", "2"];
        const deliveryStatus = String(_.get(this.props, "order.delivery_status"));
        const orderStatus = String(_.get(this.props, "order.ord_st"));
        return (
            ALLOW_DELIVERY_STATUSES.includes(deliveryStatus) &&
            ALLOW_ORDER_STATUSES.includes(orderStatus) &&
            !isGroupedOrder(this.props.order)
        );
    };

    getOrderItemChanges = () => {
        const orderChanges = _.get(this.props, "order.item_changes", []);
        const arrayChanges = Array.isArray(orderChanges) ? orderChanges : [];
        var modifiedChanges = [],
            i = 0;
        arrayChanges.map((change) => {
            let changeCp = _.cloneDeep(change);
            if (String(_.get(change, "action")) === String(ITEM_CHANGE_ACTION_ADD)) {
                changeCp.item_id = _.get(this.props, "orderItems.length", 0) + i;
                i++;
            }
            modifiedChanges.push(changeCp);
            return change;
        });
        return Array.isArray(modifiedChanges) ? modifiedChanges : [];
    };

    getStateItemChanges = () => {
        const stateChanges = _.get(this.state, "itemChanges", []);
        return Array.isArray(stateChanges) ? stateChanges : [];
    };

    getItemChanges = () => {
        const stateItemChange = this.getStateItemChanges();
        const useState = !_.isEmpty(stateItemChange) || this.state.onEdit;
        return useState ? stateItemChange : this.getOrderItemChanges();
    };

    str = (id) => {
        let { lan } = this.props;
        return _helper.getTransString(id, lan);
    };

    checkModalOpen = (prevProps) => {
        const update =
            this.props.modelController !== prevProps.modelController && _helper.isString(this.props.modelController);
        if (!update) {
            return;
        }
        const strings = this.props.modelController.split(":");
        const orderId = String(_.get(strings, 0));
        const sameId = orderId === String(this.getOrderId());
        const modelName = String(_.get(strings, 1));
        if (!sameId || !modelName) {
            return;
        }
        if (modelName === "finalizeModal") {
            this.appliedChanges();
        }
        if (modelName === "addItemModal") {
            this.addItem();
        }
    };

    //find the biggeest number and calculate the with
    getItemCountWidth = () => {
        var base = 5,
            biggest = 1,
            add = 0;
        const items = Array.isArray(this.props.orderItems) ? this.props.orderItems : [];
        const changes = this.getItemChanges();
        //find the one in items
        items.map((item) => {
            const cnt = _.get(item, "cnt", 1);
            if (cnt > biggest) biggest = cnt;
            return item;
        });
        //find the one in
        changes.map((item) => {
            const cnt = _.get(item, "new_product.cnt", 1);
            if (cnt > biggest) biggest = cnt;
            return item;
        });
        //find the correct add percetage
        //each add mean it has increase 1 digit
        while (biggest > Math.pow(10, add)) {
            add++;
        }
        return `${base + add * 2}%`;
    };

    showEditItemDialog = (item, renderingItem) => {
        if (this.allowAddItem()) this.setState({ showItemEditDialog: true, item, renderingItem });
    };

    getItemChangeItemInfo = (item) => {
        const itemChanges = this.getItemChanges();
        return itemChanges.find((change) => String(_.get(item, "item_id")) === String(_.get(change, "item_id")));
    };

    addItem = () => {
        const { orderItems } = this.props;
        const addItems = this.getItemChanges().filter(
            (change) => String(_.get(change, "action")) === String(ITEM_CHANGE_ACTION_ADD)
        );
        this.setState({
            showItemEditDetailDialog: true,
            itemChangeObject: {
                action: ITEM_CHANGE_ACTION_ADD,
                item_id: orderItems.length + addItems.length,
                new_product: {},
            },
            item: {
                pid: `add_${Math.random().toString(36).substr(2, 9)}`,
            },
        });
    };

    reverseChanges = () => {
        const shouldCallApi = !_.isEmpty(this.getOrderItemChanges());
        const updateSuggestions = () => {
            if (shouldCallApi) {
                this.props.updateSuggestions({
                    action: ITEM_CHANGE_ACTION_CANCEL_ALL,
                    order_id: this.getOrderId(),
                });
            }
            this.setState({ itemChanges: [], onEdit: false });
            this.clearLocalNewPrices();
        };
        Modal.confirm({
            title: this.props.intl.formatMessage({ id: "warning" }),
            content: this.props.intl.formatMessage({ id: "are_you_sure_reverse_all" }),
            okText: this.props.intl.formatMessage({ id: "continue" }),
            cancelText: this.props.intl.formatMessage({ id: "cancel" }),
            onOk() {
                updateSuggestions();
            },
        });
    };

    suggestedChanges = () => {
        const itemChanges = this.getItemChanges();
        const originalItems = Array.isArray(this.props.orderItems) ? this.props.orderItems : [];
        const isNewItemEmpty = isNoMoreItems(originalItems, itemChanges);
        const updateSuggestions = () => {
            this.props.updateSuggestions({
                action: _.isEmpty(itemChanges) ? ITEM_CHANGE_ACTION_CANCEL_ALL : ITEM_CHANGE_ACTION_SUGGEST,
                order_id: this.getOrderId(),
                item_changes: itemChanges,
            });
            this.setState({ itemChanges: [], onEdit: false });
            this.clearLocalNewPrices();
        };
        const cancelOrder = () => {
            this.props.cancelOrder(this.getOrderId());
            this.setState({ itemChanges: [], onEdit: false });
            this.clearLocalNewPrices();
        };
        Modal.confirm({
            title: this.props.intl.formatMessage({ id: "warning" }),
            content: this.props.intl.formatMessage({
                id: isNewItemEmpty ? "are_you_sure_reject_all" : "are_you_sure_send_to_customer",
            }),
            okText: this.props.intl.formatMessage({ id: "continue" }),
            cancelText: this.props.intl.formatMessage({ id: "cancel" }),
            onOk() {
                isNewItemEmpty ? cancelOrder() : updateSuggestions();
            },
        });
    };

    appliedChanges = () => {
        const itemChanges = this.getItemChanges();
        const originalItems = Array.isArray(this.props.orderItems) ? this.props.orderItems : [];
        const isNewItemEmpty = isNoMoreItems(originalItems, itemChanges);
        const clearModalContoller = () => this.clearModalContoller();
        const cancelOrder = () => {
            this.props.cancelOrder(this.getOrderId());
            this.setState({ itemChanges: [], onEdit: false });
            this.clearLocalNewPrices();
            clearModalContoller();
        };
        if (!isNewItemEmpty) {
            this.setState({ showETADialog: true });
            clearModalContoller();
        } else {
            Modal.confirm({
                title: this.props.intl.formatMessage({ id: "warning" }),
                content: this.props.intl.formatMessage({ id: "are_you_sure_reject_all" }),
                okText: this.props.intl.formatMessage({ id: "continue" }),
                cancelText: this.props.intl.formatMessage({ id: "cancel" }),
                onOk() {
                    cancelOrder();
                },
                onCancel() {
                    clearModalContoller();
                },
            });
        }
    };

    clearLocalNewPrices = () => {
        let newPrices = _.cloneDeep(this.props.newPrices);
        delete newPrices[this.getOrderId()];
        this.props.setState({
            newPrices: newPrices,
        });
    };

    renderMainLine = (item, changeItem, originalItem) => {
        const storeCurrency = store.getState().store?.storeCurrencyAndPricePlan?.store_currency ?? "CAD";
        const shouldCross = !_.isEmpty(changeItem);
        const isRemove = _.get(changeItem, "action", 0) === ITEM_CHANGE_ACTION_REMOVE;
        const onItemChange = () => this.showEditItemDialog(originalItem, item);
        const amt = (item.pc || item.pcIn) * item.cnt;
        const resturant = isRestaurant(this.props.orderStore);
        const renderCount = () => {
            return (
                <div style={{ minWidth: this.getItemCountWidth() }} className="item-cnt-row">
                    <b className="item-cnt">{item.cnt}</b>
                    <b className="item-cnt-x">x</b>
                </div>
            );
        };

        const renderName = () => {
            return (
                <div className="item-name-link-sku-container">
                    <div className="item-name-link-container">
                        <b> {this.str(item.nm)}</b>
                        <span className="h-spacing-8" />
                        <a
                            href={`${config.H5_URL}product/${item.pid}`}
                            target="_blank"
                            onClick={(e) => e.stopPropagation()}
                            rel="noreferrer"
                        >
                            <HiExternalLink className="external-link-icon" />
                        </a>
                    </div>
                    {resturant ? (
                        ""
                    ) : (
                        <div>
                            ({<FormattedMessage id="sku" />}: {item.sku})
                        </div>
                    )}
                </div>
            );
        };

        return (
            <div onClick={() => onItemChange()} className={`item-name-price ${shouldCross ? "cross" : ""}`}>
                {renderCount()}
                {renderName()}
                <b className="item-price">{_helper.getCurrencyFormattedString(amt, storeCurrency)}</b>
                {this.allowAddItem() ? (
                    <div className="item-edit-button">{!shouldCross || isRemove ? <FiMoreVertical /> : ""}</div>
                ) : null}
            </div>
        );
    };

    renderOptionsLines = (item, changeItem, originalItem) => {
        const shouldCross = !_.isEmpty(changeItem);
        const onItemChange = () => this.showEditItemDialog(originalItem, item);
        const subOptions = getItemOptionsArray(item.opts, this.props.lan);
        return subOptions.length ? (
            <div onClick={() => onItemChange()} className={`item-options`}>
                <span
                    style={{
                        width: this.getItemCountWidth(),
                        marginRight: 10,
                    }}
                >
                    &nbsp;
                </span>
                <div className={`${shouldCross ? "cross" : ""}`}>
                    {subOptions.map((opt) => {
                        return (
                            <div key={`item-option-${opt.id}`}>
                                <span>-&nbsp;</span>
                                <span className="option-name"> {opt.nm}: </span>
                                <span className="option-values">{opt.optStr}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        ) : (
            ""
        );
    };

    renderSpecialRequestLine = (item, changeItem, originalItem) => {
        const shouldCross = !_.isEmpty(changeItem);
        const onItemChange = () => this.showEditItemDialog(originalItem, item);
        return item.special_request?(
            <div onClick={() => onItemChange()} className={`item-options`}>
                <span
                    style={{
                        width: this.getItemCountWidth(),
                        marginRight: 10,
                    }}

                >
                    &nbsp;
                </span>
                <div className={`${shouldCross ? "cross" : ""}`}>
                    <span>-&nbsp;</span>
                    <span className="option-name"> <FormattedMessage id="special_request"/>: </span>
                    <span className="option-values"> {item.special_request}</span>
                   
                </div>
            </div>
        ) : (
            ""
        );
    };

    renderItemChangeIndicateText = (changeItem, originalItem) => {
        const action = _.get(changeItem, "action");
        const newItemNm = this.str(_.get(changeItem, "new_product.nm", ""));
        const originalItemNm = this.str(_.get(originalItem, "nm", {}));
        if (action === ITEM_CHANGE_ACTION_ADD) {
            return (
                <div className="item-change-indicate-text">
                    <FormattedMessage id="add_x" values={{ x: newItemNm }} />
                </div>
            );
        }
        if (action === ITEM_CHANGE_ACTION_REPLACE && newItemNm === originalItemNm) {
            return (
                <div className="item-change-indicate-text">
                    <FormattedMessage id="update_x" values={{ x: originalItemNm }} />
                </div>
            );
        }
        if (action === ITEM_CHANGE_ACTION_REPLACE && newItemNm !== originalItemNm) {
            return (
                <div className="item-change-indicate-text">
                    <FormattedMessage
                        id="replace_x_with_y"
                        values={{
                            x: originalItemNm,
                            y: newItemNm,
                        }}
                    />
                </div>
            );
        }
        if (action === ITEM_CHANGE_ACTION_REMOVE) {
            return (
                <div className="item-change-indicate-text">
                    <FormattedMessage id="remove_x" values={{ x: originalItemNm }} />
                </div>
            );
        }
    };

    renderItemChange = (changeItem, originalItem) => {
        const newItem = _.get(changeItem, "new_product", {});
        const shouldRender = !_.isEmpty(newItem);
        if (shouldRender)
            return (
                <div className="item-change-text">
                    {this.renderMainLine(newItem, {}, originalItem)}
                    {this.renderOptionsLines(newItem, {}, originalItem)}
                </div>
            );
    };

    renderAddedItems = () => {
        const itemChanges = this.getItemChanges();
        const addItems = itemChanges.filter(
            (change) => String(_.get(change, "action")) === String(ITEM_CHANGE_ACTION_ADD)
        );
        if (Array.isArray(addItems)) {
            return addItems.map((item, i) => {
                let newItem = _.get(item, "new_product", {});
                newItem.amt = newItem.cnt * (newItem.pc || newItem.pcIn);
                newItem.item_id = _.get(this.props, "orderItems.length", 0) + i;
                return (
                    <div className="item-wrapper item-change-text" key={`order-item-added${newItem.pid}-${i}`}>
                        <div className="light-grey-border"></div>
                        {this.renderItemChangeIndicateText(item, {})}
                        {this.renderMainLine(newItem, {}, newItem)}
                        {this.renderOptionsLines(newItem, {}, newItem)}
                    </div>
                );
            });
        }
    };

    renderDivider = () => {
        return <div className="live-order-items-divider"></div>;
    };

    renderPendingForCustomerText = () => {
        const showMessage = !_.isEmpty(this.getOrderItemChanges()) && this.allowAddItem();
        if (showMessage) {
            return (
                <div className="pending-for-customer-confirmation-text">
                    <FormattedMessage id="pending_for_customer_confirmation" />
                </div>
            );
        }
    };

    renderEditButtons = () => {
        let datas = [];

        datas.push({
            tips: "add",
            variant: "outlined",
            onClick: () => this.addItem(),
        });

        if (!_.isEmpty(this.getItemChanges())) {
            datas.push({
                tips: "reverse_all_changes",
                variant: "outlined",
                onClick: () => this.reverseChanges(),
            });
        }

        if (this.getCurrentStyle() !== "list" && !_.isEmpty(this.getItemChanges())) {
            datas.push({
                tips: "apply_changes",
                variant: "outlined",
                onClick: () => this.appliedChanges(),
            });
        }

        if (this.state.onEdit) {
            datas.push({
                tips: "suggest_changes",
                variant: "contained",
                onClick: () => this.suggestedChanges(),
            });
            this.props.setState({onEdit: true });
        }else{
            this.props.setState({onEdit: false }); 
        }

        if (!this.allowAddItem()) {
            datas = [];
        }

        return datas.length ? (
            <div className="item-edit-row">
                {datas.map((data) => (
                    <div key={`item-edit-button-${data.tips}`} className="item-edit-button">
                        <StyledButton {...data} color="secondary">
                            <b className="item-edit-text">
                                <FormattedMessage id={data.tips} />
                            </b>
                        </StyledButton>
                    </div>
                ))}
            </div>
        ) : null;
    };

    renderItemEditDialog = () => {
        return (
            <EditItemDialog
                {...this.props}
                state={{
                    ...this.state,
                    itemChanges: this.getItemChanges(),
                }}
                setState={(values) => this.setState(values)}
            />
        );
    };

    renderItemDetailEditDialog = () => {
        return (
            <EditItemDetailDialog
                {...this.props}
                clearModalContoller={() => this.clearModalContoller()}
                state={{
                    ...this.state,
                    itemChanges: this.getItemChanges(),
                }}
                setState={(values) => this.setState(values)}
            />
        );
    };

    renderETADialog = () => {
        const show = this.state.showETADialog;
        return (
            <ETADialog
                onCloseClick={() => this.setState({ showETADialog: false })}
                displayModal={show}
                orderStore={this.props.orderStore}
                order={this.props.order}
                onSubmitClick={(values) => {
                    const itemChanges = this.getItemChanges();
                    this.props.updateSuggestions({
                        ...values,
                        action: ITEM_CHANGE_ACTION_APPLIED,
                        order_id: this.getOrderId(),
                        item_changes: itemChanges,
                    });
                    this.setState({ itemChanges: [], onEdit: false });
                    this.clearLocalNewPrices();
                }}
            />
        );
    };

    renderOrderInfo = (order) => {
        const getStore = () => {
            const orderGid = _.get(order, "g_id", "");
            const storesData = _.get(this.props, "storesData", []);
            return storeHelper.getStoreWithGid(orderGid, storesData);
        };

        const renderLeftInfo = () => {
            return (
                <div className="order-item-grouped-order-order-info-row-left-info">
                    <span>#{formatOrderId(getOrderId(order))}</span>
                    <span>&nbsp;·&nbsp;</span>
                    <span>{getOrderTotalStr(order, getStore(order))} </span>
                    <span>&nbsp;·&nbsp;</span>
                    <span>{getPaymentStringWithCreditCardInfo(order)} </span>
                    <span>&nbsp;·&nbsp;</span>
                    <span>{getCustomerName(order)}</span>
                </div>
            );
        };

        const renderRightInfo = () => {
            const status = getShortDeliveryStatus(order, getStore(order));
            return (
                <div
                    style={{
                        color: `#${config.ORDER_STATUS_COLOR_MAPPING[status]}`,
                    }}
                    className="order-item-grouped-order-order-info-row-right-info"
                >
                    <FormattedMessage id={`status_${status}` || " "} />
                    &nbsp; &nbsp;
                    {this.state[getOrderId(order)] ? (
                        <DownOutlined style={caretStyle} />
                    ) : (
                        <RightOutlined style={caretStyle} />
                    )}
                </div>
            );
        };
        return (
            <div
                onClick={() => this.setState({ [getOrderId(order)]: !this.state[getOrderId(order)] })}
                className="order-item-grouped-order-order-info-row"
            >
                {renderLeftInfo()}
                {renderRightInfo()}
            </div>
        );
    };

    renderOrderItems = (orderItems) => {
        const items = Array.isArray(orderItems) ? orderItems : [];
        return items.map((item, i) => {
            const itemChange = this.getItemChangeItemInfo(item);
            return (
                <div className="item-wrapper" key={`order-item-${item.pid}-${i}`}>
                    <div className="light-grey-border"></div>
                    {this.renderItemChangeIndicateText(itemChange, item)}
                    {this.renderMainLine(item, itemChange, item)}
                    {this.renderOptionsLines(item, itemChange, item)}
                    {this.renderSpecialRequestLine(item, itemChange, item)}
                    {this.renderItemChange(itemChange, item)}
                </div>
            );
        });
    };

    renderAllItems = () => {
        const { orderItems } = this.props;
        const renderEachOrder = isGroupedOrder(this.props.order) && !_.get(this.props, "state.groupedByItem", false);
        const orders = Array.isArray(_.get(this.props, "order.orders")) ? _.get(this.props, "order.orders") : [];
        if (renderEachOrder) {
            return orders.map((order) => (
                <div
                    key={`order-item-grouped-order-order-detail-row-${getOrderId(order)}`}
                    className="order-item-grouped-order-order-detail-row"
                >
                    {this.renderOrderInfo(order)}
                    <Collapse in={!this.state[getOrderId(order)]}>
                        {this.renderOrderItems(_.get(order, "ord_itm"))}
                    </Collapse>
                </div>
            ));
        } else {
            return this.renderOrderItems(orderItems);
        }
    };

    render() {
        return (
            <div className="order-items">
                {this.renderAllItems()}
                {this.renderAddedItems()}
                {this.renderDivider()}
                {this.renderPendingForCustomerText()}
                {this.renderEditButtons()}
                {this.renderItemEditDialog()}
                {this.renderItemDetailEditDialog()}
                {this.renderETADialog()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    state: _.get(state, "liveOrders", {}),
    storesData: _.get(state, "managedStores.activeStores", []),
    newPrices: _.get(state, "liveOrders.newPrices", {}),
    modelController: _.get(state, "liveOrders.modelController", ""),
    listStyle: _.get(state, "liveOrders.listStyle", "list"),
});

const mapDispatchToProps = {
    updateSuggestions: _actions.updateSuggestions,
    cancelOrder: (orderId) =>
        _actions.updateOrder({
            order_id: orderId,
            action: 3,
        }),
    setState: _actions.setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderItems));
