import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl } from "react-intl";
import { Dropdown, Menu, Checkbox } from "antd";
import { IoIosArrowDown } from "react-icons/io";
import { getStatusOptions, FILTER_KEYS, getOptionValue } from "../../helper-order-list";
import actions from "../../../../slices/order-history";

class StatusFilters extends Component {
    getCurrentFilter = () => {
        const fitler = _.get(this.props, `state.searchConditions.search_condition.${FILTER_KEYS.status}`);
        return Array.isArray(fitler) ? fitler : [];
    };

    str = (id, values) => this.props.intl.formatMessage({ id: id ? id : " " }, values);

    getFilterSectionText = () => {
        return "order_status";
    };

    renderDropDownMenu = () => {
        const filter = this.getCurrentFilter();
        const options = getStatusOptions(this.props.state, this.props.storeRecords);

        const onCheckboxChange = (obj) => {
            const searchValue = getOptionValue(obj, "status", filter, "normal", this.props.storeRecords);
            this.props.fetchOrderList({ [FILTER_KEYS.status]: searchValue });
        };

        const isCheck = (obj) => {
            const isAll = obj.label === "all";
            const value = obj.value;
            if (isAll) {
                return _.isEqual(filter, value);
            }
            return filter.includes(value);
        };

        return (
            <Menu style={{ maxWidth: 230 }}>
                {options.map((obj) => (
                    <Menu.Item key={obj.label}>
                        <div className="order-list-title-filter-drop-down-item">
                            <Checkbox checked={isCheck(obj)} onChange={() => onCheckboxChange(obj)}>
                                <span className="order-list-title-filter-drop-down-item-text">
                                    {this.str(`category_${obj.label}`)}
                                </span>
                                <span>({obj.total})</span>
                            </Checkbox>
                        </div>
                    </Menu.Item>
                ))}
            </Menu>
        );
    };

    renderFilterSection = () => {
        return (
            <Dropdown overlay={this.renderDropDownMenu()}>
                <div className="order-list-title-filter">
                    <div className="order-list-title-filter-text">{this.str(this.getFilterSectionText())}</div>
                    <div className="order-list-title-filter-icon">
                        <IoIosArrowDown />
                    </div>
                </div>
            </Dropdown>
        );
    };

    render() {
        return <div>{this.renderFilterSection()}</div>;
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    state: _.get(state, "orders-page", {}),
    storeRecords: _.get(state, "store.records", {}),
});

const mapDispatchToProps = {
    fetchOrderList: actions.fetchOrderList,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(StatusFilters));
