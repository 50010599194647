import { Button, Form, Switch } from "antd";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import _ from "lodash";
import { getSwitchSingleBlock, getDoubleBlockOptions, getDescBlock } from "../../../../components/form";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../../../../components/wrapper";
import { RootState } from "../../../../app/reducer";
import { Prompt } from "react-router-dom";
import { getStoreDetails, updateStoreDetails } from "../../../../services/store";
import { setTipsState, resetTipState } from "../../../../slices/store";
import ObjectModel from "../../../../util/models";
import StoreTipsSettings from "../../../../util/models/store-tips-settings";
import { InputNumber } from "antd";
import config from "config";
import { split } from "lodash";

type TypeKey = "CAD" | "GBP" | "CNY" | "USD" | "EUR";
type Type = {[key in TypeKey]: string}
const currencySymbols: Type = config.CURRENCY_SYMBOL;

const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        { path: "/settings", breadcrumbName: "settings_overview" },
        { path: "/settings/tipsSettings", breadcrumbName: "tips_settings" },
    ],
};

function App(): JSX.Element | null {
    const intl = useIntl();
    const dispatch = useDispatch();
    const tipsSettings = useSelector((state: RootState) => state.store?.storeTipsSettings);
    const storeCurrency: TypeKey = useSelector((state: RootState) => state?.store?.storeCurrencyAndPricePlan?.store_currency) ?? "CAD";
    const records = useSelector((state: RootState) => state.store?.records);
    const [drawerOpen0, setDrawerOpen0] = useState<boolean>(false);
    const [drawerOpen1, setDrawerOpen1] = useState<boolean>(false);
    const [drawerOpen2, setDrawerOpen2] = useState<boolean>(false);
    const [drawerOpen3, setDrawerOpen3] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState<any>();
    const [touched, setTouched] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        dispatch(getStoreDetails());
    }, [dispatch]);
    useEffect(() => {
        if (!initialValues) {
            setInitialValues(_.cloneDeep(tipsSettings));
        }
    }, [tipsSettings, initialValues]);

    const clearForm = () => {
        dispatch(resetTipState(initialValues));
        setTouched(false);
    };

    const setState = (name: any, value: any) => {
        const tipsCopy = _.cloneDeep(tipsSettings);
        _.set(tipsCopy, name, value);
        let pairName = "";
        if(value == -1){
            const isPct = name.includes("default_pct");
            const isAmt = name.includes("default_amt");
            const splitName = _.slice(name.split("_"),2).join('_');
            if(isPct){
                pairName = `default_amt_${splitName}`;
                _.set(tipsCopy, pairName, value);
            }else if (isAmt){
                pairName = `default_pct_${splitName}`;
                _.set(tipsCopy, pairName, value);
            }
        }
        setTouched(!_.isEqual(tipsCopy, initialValues));
        dispatch(setTipsState({ name, value }));
        if(pairName != ""){
            name = pairName;
            dispatch(setTipsState({ name, value }));
        }
    };

    const onFinish = () => {
        setTouched(false);
        closeAllDrawers();
        dispatch(
            updateStoreDetails(
                new ObjectModel(StoreTipsSettings).convertToPostData(tipsSettings, records, "tips_settings")
            )
        );
    };

    const closeAllDrawers = () => {
        setDrawerOpen0(false);
        setDrawerOpen1(false);
        setDrawerOpen2(false);
        setDrawerOpen3(false);
    };

    const getCustomNumberSelect = (name: string, availableValuesName: string, type: string) => (
        <select
            className="custom-input-textfield wide-option"
            value={_.get(tipsSettings, `${name}_${type}`, undefined)}
            onChange={(e) => setState(`${name}_${type}`, e.target.value)}
        >
            <option key="-1" value="-1">{intl.formatMessage({ id: "no_tips" })}</option>
            {
                // For each record index (max 5 records)
                [0, 1, 2, 3, 4]
                    .map((index) => _.get(tipsSettings, `${availableValuesName}_${index}`, undefined))
                    .filter((value) => value !== undefined)
                    .map((value) => (
                        <option key={value} value={value}>
                            {value}
                        </option>
                    ))
            }
        </select>
    );

    const getNumberSelectRow = (name: string, availableValuesName: string, type: string, perc: boolean) => (
        <div className="number-input-row">
            {
                <div style={{ width: "68px" }}>
                    <FormattedMessage id={type} />
                </div>
            }
            {!perc && <div style={{ marginRight: "8px" }}>$</div>}
            {getCustomNumberSelect(name, availableValuesName, type)}
            {perc && <div style={{ marginLeft: "8px" }}>%</div>}
        </div>
    );

    const getEditDraw = (
        name: string,
        availableValuesName: string,
        setState: any,
        currState: boolean,
        perc: boolean
    ) => (
        <React.Fragment>
            <div className="edit-option-container">
                {getDisplayInput(false, name, perc)}
                <div className="edit-option" onClick={() => setState(!currState)}>
                    <FormattedMessage id={currState ? "done" : "edit"} />
                </div>
            </div>
            <div className={currState ? "expanded" : "collapsed"}>
                <div className="edit-three-options-container">
                    {getNumberSelectRow(name, availableValuesName, "delivery", perc)}
                    {getNumberSelectRow(name, availableValuesName, "in_store", perc)}
                    {getNumberSelectRow(name, availableValuesName, "pickup", perc)}
                </div>
            </div>
        </React.Fragment>
    );

    const getEditMulti = (name: string, setState: any, currState: boolean, perc: boolean) => {
        const tips4 = _.get(tipsSettings, `${name}_4`);
        const hasFive = tips4 === undefined || tips4 === null ? 0 : 1;
        return (
            <React.Fragment>
                <div className="edit-option-container">
                    {getDisplayInput(true, name, perc)}
                    <div className="edit-option" onClick={() => setState(!currState)}>
                        <FormattedMessage id={currState ? "done" : "edit"} />
                    </div>
                </div>
                <div className={currState ? "expanded" : "collapsed"}>
                    <div className="edit-three-options-container">
                        {getMultiNumberInputRow(name, "0")}
                        {getMultiNumberInputRow(name, "1")}
                        {getMultiNumberInputRow(name, "2")}
                        {getMultiNumberInputRow(name, "3")}
                        {getMultiNumberInputRow(name, "4")}
                        <AiFillPlusCircle
                            onClick={() => addRowHandler(name, hasFive)}
                            style={{
                                width: "68px",
                                marginTop: "5px",
                                color: "#3898c8",
                                display: hasFive ? "none" : "",
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const getMultiNumberInputRow = (name: string, num: string) => {
        const tips3 = _.get(tipsSettings, `${name}_3`);
        const tipsNameNum = _.get(tipsSettings, `${name}_${num}`);
        const hasThree = tips3 === undefined || tips3 === null ? 0 : 1;
        const noVal = tipsNameNum === undefined || tipsNameNum === null ? 1 : 0;
        return (
            <div className="number-input-row" style={{ display: noVal ? "none" : "flex" }}>
                {getCustomNumberInput(name, num)}
                <AiFillMinusCircle
                    onClick={() => removeRowHandler(name, num, hasThree)}
                    style={{ width: "35px", cursor: "pointer", color: "#3898c8", display: hasThree ? "" : "none" }}
                />
            </div>
        );
    };

    const removeRowHandler = (name: string, num: string, hasThree: any) => {
        if (hasThree) {
            let i = parseInt(num);
            for (i; i < 4; i++) {
                setState(`${name}_${i.toString()}`, _.get(tipsSettings, `${name}_${(i + 1).toString()}`, null));
            }
            setState(`${name}_4`, null);
        }
    };

    const addRowHandler = (name: string, hasFive: any) => {
        if (!hasFive && name === "tips_percent") {
            // New record's value by default should be greater by 5% for tips percentage.
            _.get(tipsSettings, `${name}_3`) === undefined || _.get(tipsSettings, `${name}_3`) === null
                ? setState(`${name}_3`, _.get(tipsSettings, `${name}_2`) + 5)
                : setState(`${name}_4`, _.get(tipsSettings, `${name}_3`) + 5);
        } else if (!hasFive && name === "tips_amount") {
            // New record's value by default should be greater by $2 for tips amount.
            _.get(tipsSettings, `${name}_3`) === undefined || _.get(tipsSettings, `${name}_3`) === null
                ? setState(`${name}_3`, _.get(tipsSettings, `${name}_2`) + 2)
                : setState(`${name}_4`, _.get(tipsSettings, `${name}_3`) + 2);
        }
    };

    const checkIfNull = (name: string, perc: boolean, type: string) => {
        return _.get(tipsSettings, name + type) === undefined || _.get(tipsSettings, name + type) === null
            ? ""
            : (_.get(tipsSettings, name + type) == -1)? `${intl.formatMessage({ id: "no_tips" })} , `
            : (`${perc ? "" : currencySymbols[storeCurrency]}${_.get(tipsSettings, name + type)}${perc ? "%" : ""}, `);
    };

    const getDisplayInput = (multi: boolean, name: string, perc: boolean) => {
        let value = multi
            ? `${checkIfNull(name, perc, "_0")}${checkIfNull(name, perc, "_1")}` +
              `${checkIfNull(name, perc, "_2")}${checkIfNull(name, perc, "_3")}` +
              `${checkIfNull(name, perc, "_4")}`
            : `${checkIfNull(name, perc, "_delivery")}${checkIfNull(name, perc, "_in_store")}` +
              `${checkIfNull(name, perc, "_pickup")}`;
        value = value?.replace?.(/,*\s*$/, "");
        return <div className="ant-mock-input-container">{value}</div>;
    };

    const getCustomNumberInput = (name: string, type: string) => (
        <InputNumber
            className="custom-input-textfield surAmount-field ml-1"
            min={0}
            type="number"
            value={_.get(tipsSettings, `${name}_${type}`, 0)}
            onChange={(e) => setState(`${name}_${type}`, e)}
        />
    );

    const getDollar = () => <div className="dollarBefore">{currencySymbols[storeCurrency]} &nbsp; {getCustomNumberInput("tips_order", "limit")}</div>;

    const renderTipsOptions = () => (
        <React.Fragment>
            {getDescBlock("order_limit", false, "hint_order_limit", getDollar())}
            {getDoubleBlockOptions(
                ["tips_in_percentage", "tips_in_dollar_values"],
                ["hint_tips_in_percentage", "hint_tips_in_dollar_values"],
                [
                    getEditMulti("tips_percent", setDrawerOpen2, drawerOpen2, true),
                    getEditMulti("tips_amount", setDrawerOpen3, drawerOpen3, false),
                ]
            )}
            {getDoubleBlockOptions(
                ["default_percentage_values", "default_amount_values"],
                ["hint_default_percentage_values", "hint_default_amount_values"],
                [
                    getEditDraw("default_pct", "tips_percent", setDrawerOpen0, drawerOpen0, true),
                    getEditDraw("default_amt", "tips_amount", setDrawerOpen1, drawerOpen1, false),
                ]
            )}
        </React.Fragment>
    );

    const getEnabledSwitch = (
        <Switch
            style={{ marginLeft: "30px" }}
            checked={_.get(tipsSettings, "tips_enabled", 1)}
            onChange={() => setState("tips_enabled", !_.get(tipsSettings, "tips_enabled", 1))}
        />
    );

    const renderForm = () => {
        return (
            <Form form={form} name="tips-settings" onFinish={onFinish} initialValues={tipsSettings}>
                {getSwitchSingleBlock("enable_tips", getEnabledSwitch)}
                {_.get(tipsSettings, "tips_enabled", true) ? renderTipsOptions() : null}
                <div className="setting-actions floating-actions" style={{ marginTop: 14 }}>
                    <Button size="large" disabled={!touched} style={{ marginRight: 14 }} onClick={clearForm}>
                        <FormattedMessage id="cancel" />
                    </Button>
                    <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        disabled={!touched}
                        className="save-button-handler"
                    >
                        <FormattedMessage id="save_changes" />
                    </Button>
                </div>
            </Form>
        );
    };

    return !_.isEmpty(tipsSettings) ? (
        <Wrapper helmet={{ title: "tips_settings" }} breadcrumb={breadcrumb}>
            <Prompt when={touched} message={intl.formatMessage({ id: "unsaved_changes" })} />
            <div className="display-options-page">{renderForm()}</div>
        </Wrapper>
    ) : null;
}

export default App;
