const routes: any = Object.freeze({
    LOGIN: "api/rest/adm/v8/adminlogin/",
    PROFILE: "api/rest/adm/v7/admin_profile/",
    SALES_REPORT: "api/rest/adm/v9/salesreport",
    STORE_SWITCH: "api/rest/adm/v4/customer",
    STORE_DETAILS: "api/rest/adm/v8/storedetails",
    STORE_DETAILS_PARTS: "api/rest/adm/v9/storedetails",
    STORE_AVAILABILITY: "api/rest/adm/v8/time_list",
    STORE_LIST: "api/rest/adm/v4/slst",
    IMAGE_CACHE: "api/image.php",
    IMAGE_UPLOAD: "api/rest/adm/v4/imgupload",
    PPL: "api/adm/v4/pplst",
    PPD: "api/adm/v4/ppdtl",
    MANAGED_STORES: "api/rest/adm/v8/managed_stores",
    DELIVERY_TIME_SLOTS: "api/rest/adm/v8/deliverytimeslot",
    DELIVERY_TIME_SLOTS_DELETE: "api/rest/adm/v8/deliverytimeslot/delete",
    PUBLISH: "api/rest/adm/v7/publish",
    CATEGORIES: "api/rest/adm/v4/catlist",
    CATEGORIES_DELETE: "api/rest/adm/v4/catlist/delete",
    PRODUCTS: "api/rest/adm/v4/categoryproductlist",
    PRODUCT_DETAILS: "api/rest/adm/v7/pdtl",
    TIME_LIST: "api/rest/adm/v8/time_list",
    COUPON: "api/rest/adm/v4/coupon",
    COUPON_DELETE: "api/rest/adm/v4/coupon/delete",
    VALIDATE_COUPON: "api/rest/adm/v7/coupon",
    PRODUCT_SEARCH: "api/rest/adm/v7/psearch",
    MENU: "api/rest/adm/v8/menu_admin",
    ADMIN_USERS: "api/rest/adm/v4/adminuser",
    ADMIN_USERS_DELETE: "api/rest/adm/v4/adminuser/delete",
    ADMIN_ROLES: "api/rest/adm/v8/role",
    LIVE_ORDER: "api/rest/adm/v8/liveorder",
    ORDER_HISTORY: "api/rest/adm/v9/ordlst",
    ORDER_DETAIL: "api/rest/adm/v10/orddtl",
    ORDER_DELIVERY_STATUS: "api/rest/adm/v8/order_delivery_status",
    ORDER_COMMENTS: "api/rest/adm/v4/comment",
    LIVE_ORDER_PRODUCTS: "api/v7/cplst2",
    LIVE_ORDER_PRODUCT: "api/v7/pdtlall",
    LIVE_ORDER_UPDATE_SUGGESTIONS: "api/rest/adm/v10/order_update_request",
    UPDATE_ORDER: "api/rest/adm/v10/updateorder",
    EXPORT_ORDER: "api/rest/adm/v7/exportorder",
    MERGE_ORDERS: "api/rest/adm/v9/merge_orders",
    ONLINE_STATUS: "api/rest/adm/v7/onlineorderstatus",
    ADMIN_ROLE_PERMISSIONS: "api/v8/admin_role_permission",
    PERMISSIONS: "api/rest/adm/v8/role",
    CUSTOMER_LIST: "api/rest/adm/v9/customerlist",
    CUSTOMER_MESSAGE: "api/rest/adm/v7/message",
    CUSTOMERS: "api/rest/v7/customerinfo",
    CREATE_CUSTOMER: "api/rest/adm/v9/create_customer",
    DELETE_CUSTOMER: "api/rest/adm/v9/customer_detail/delete",
    GET_CUSTOMER: "api/rest/adm/v9/customer_detail",
    GET_CUSTOMER_ADDRESS: "api/rest/adm/v9/customerAddr",
    UPDATE_CUSTOMER_PASSWORD: "api/rest/adm/v9/customerresetpwd",
    SFC: "/api/v7/sfc.php",
    DUPLICATE: "api/rest/adm/v8/dupe_product",
    GIFT_CARD_LIST: "api/rest/adm/v7/giftcard_list",
    GIFT_CARD_ACCOUNTS: "api/rest/adm/v7/giftcard_accounts",
    GIFT_CARD_HISTORY: "api/rest/adm/v7/giftcard_history",
    GIFT_CARD_MANAGEMENT: "api/rest/adm/v7/giftcard_management",
    STORE_REVIEWS: "api/rest/adm/v7/reviews/store",
    ORDER_REVIEWS: "api/rest/adm/v7/reviews/order",
    REPLY_REVIEWS: "api/rest/adm/v7/reviews",
    DASHBOARD: "api/rest/adm/v9/dashboard",
    PHONE_VERIFICATION: "api/adm/v7/vcd",
    RESET_PASSWORD: "api/rest/adm/v7/resetpwd",
    CUSTOMER_RESET_PASSWORD: "api/v7/consumerresetpwd",
    REFERRAL_CODE: "api/adm/v7/referral_code",
    DELIVERY_LOCAL_TEST: "api/v1/organization_store/organization_store",
    REFERRAL_LIST: "api/rest/adm/v9/referrals",
    REFERRAL_TRANSACTIONS: "api/rest/adm/v9/referral_transactions",
    REFERRALS_LIST: "api/rest/adm/v9/referral_list",
    REFERRAL_ORDER_HISTORY: "api/rest/adm/v9/referral_orders",
    REGISTER: "api/rest/adm/v4/adminreg",
    STORE_MANAGEMENT: "api/rest/adm/v4/storemgmt",
    ASSISTED_SIGNUP: "api/rest/v9/assisted_store_signup",
    CART_TOTAL: "api/rest/adm/v9/ctotal",
    AIO: "api/rest/adm/v5/aio",
    QUOTES: "api/rest/adm/v10/quotes",
    CREATE_STORE: "api/rest/adm/v9/create_store",
    VALIDATE_BARCODE: "api/rest/adm/v9/validate_barcode",
    CONTENT_PAGES: "api/rest/adm/v10/content_pages_list",
    CONTENT_PAGE: "api/rest/adm/v10/content_page",
    DELETE_CONTENT_PAGE: "api/rest/adm/v10/content_page/delete",
    COURIER_METHODS: "api/rest/adm/v10/carrier_methods",
    ORDER_TRACKING: "api/rest/adm/v10/order_shipping_tracking",
    DELETE_ORDER_TRACKING: "api/rest/adm/v10/order_shipping_tracking/delete",
    GROUP_PURCHASE_LIST: "api/rest/adm/v10/gplst",
    DOORDASH_API_URL: "api/rest/adm/v9/delivery_request",
    ORDER_ID_GENERATOR: "api/rest/adm/v9/next_order_id",
    DELIVERY_TIMESLOT: "api/rest/adm/v9/3rd_party_delivery_timeslot",
    ADMIN_CUSTOMER_ADDRESS: "api/rest/adm/v10/customer_address",
    GEOCODE_PROXY: "api/v8/geocode_proxy",
    AUTOCOMPLETE_PROXY: "api/v8/autocomplete_proxy",
});

export default routes;
