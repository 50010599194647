// @ts-nocheck
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Modal } from "antd";
import _ from "lodash";
import qs from "querystring";
import { HiInformationCircle } from "react-icons/hi";
import { toast } from "react-toastify";
import actions from "slices/live-order";
import { RootState } from "../app/reducer";
import { createMessage } from "../components/message";
import config from "../config";
import { getIntl } from "../locale";
import {
    getStoreDetailPartSuccess,
    getStoreDetailSuccess,
    getStoreTypesSuccess,
    setDoordashDeliveryDetails,
    setOnlineStatusSuccess,
    setStoreState,
    setThirdPartyDeliveryState,
} from "../slices/store";
import { oauth } from "../util/api";
import routes from "../util/api/routes";
import ObjectModel from "../util/models";
import StoreThirdPartyDelivery from "../util/models/store-3rd-party-delivery";
import StoreAutoAcceptOrder from "../util/models/store-auto-accept-order";
import StoreAutoDiscounts from "../util/models/store-auto-discounts";
import StoreBasic from "../util/models/store-basic";
import StoreCheckout from "../util/models/store-checkout";
import StoreCurrencyAndPricePlan from "../util/models/store-currency-and-price-plan";
import StoreDisplayOptions from "../util/models/store-display-options";
import StoreGeneralInfo from "../util/models/store-general-info";
import StoreHours from "../util/models/store-hours";
import StoreImages from "../util/models/store-images";
import StoreNotifications from "../util/models/store-notifications";
import StorePaymentOptions from "../util/models/store-payment-options";
import StorePickupDelivery from "../util/models/store-pickup-delivery";
import StorePublicNotice from "../util/models/store-public-notice";
import StoreReferrals from "../util/models/store-referrals";
import StoreRestaurantExtras from "../util/models/store-restaurant-extras";
import StoreReviewBooster from "../util/models/store-review-booster";
import StoreSelfServe from "../util/models/store-self-serve";
import StoreSEO from "../util/models/store-seo";
import StoreShipping from "../util/models/store-shipping";
import StoreTipsSettings from "../util/models/store-tips-settings";
function emptyFunction() {
    // does nothing
}

export const getOnlineStatus =
    () =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const r: any = await oauth("ONLINE_STATUS")({
                method: "GET",
            });

            if (r?.RC === 200) {
                dispatch(setOnlineStatusSuccess(r.online_status));
            }
        } catch (e) {
            // Handle error
        }
    };

export const setOnlineStatus =
    (status: number) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const r: any = await oauth("ONLINE_STATUS")({
                method: "POST",
                body: JSON.stringify({
                    data: {
                        action: status,
                    },
                }),
            });

            if (r?.RC === 200) {
                dispatch(getOnlineStatus());
            }
        } catch (e) {
            // Handle error
        }
    };

export const getStoreDetails =
    () =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setStoreState({ loading: true }));
        const intl = getIntl();

        try {
            const response: any = await oauth("STORE_DETAILS")({
                method: "GET",
            });
            if (response?.RC === 200) {
                const storeReviewBooster = new ObjectModel(StoreReviewBooster, response?.records).getData();
                if (_.isEmpty(storeReviewBooster.review_hint)) {
                    storeReviewBooster.review_hint = intl.formatMessage({ id: "tell_us_about_our_service" });
                }
                dispatch(
                    getStoreDetailSuccess({
                        storeInformation: new ObjectModel(StoreGeneralInfo, response?.records).getData(),
                        storeSEO: new ObjectModel(StoreSEO, response?.records).getData(),
                        storeImages: new ObjectModel(StoreImages, response?.records).getData(),
                        storeCurrencyAndPricePlan: new ObjectModel(
                            StoreCurrencyAndPricePlan,
                            response?.records
                        ).getData(),
                        storePublicNotice: new ObjectModel(StorePublicNotice, response?.records).getData(),
                        storeDisplayOptions: new ObjectModel(StoreDisplayOptions, response?.records).getData(),
                        storePaymentOptions: new ObjectModel(StorePaymentOptions, response?.records).getData(),
                        storeBasic: new ObjectModel(StoreBasic, response?.records).getData(),
                        storeRestaurantExtras: new ObjectModel(StoreRestaurantExtras, response?.records).getData(),
                        storeTipsSettings: new ObjectModel(StoreTipsSettings, response?.records).getData(),
                        storeHours: new ObjectModel(StoreHours, response?.records).getData(),
                        storePickupDelivery: new ObjectModel(StorePickupDelivery, response?.records).getData(),
                        storeSelfServe: new ObjectModel(StoreSelfServe, response?.records).getData(),
                        storeAutoDiscounts: new ObjectModel(StoreAutoDiscounts, response?.records).getData(),
                        storeNotifications: new ObjectModel(StoreNotifications, response?.records).getData(),
                        storeShipping: new ObjectModel(StoreShipping, response?.records).getData(),
                        storeReferrals: new ObjectModel(StoreReferrals, response?.records).getData(),
                        storeThirdPartyDelivery: new ObjectModel(
                            StoreThirdPartyDelivery(response?.records),
                            response?.records
                        ).getData(),
                        storeCheckout: new ObjectModel(StoreCheckout, response?.records).getData(),
                        autoAcceptOrder: new ObjectModel(StoreAutoAcceptOrder, response?.records).getData(),
                        records: response.records,
                        storeReviewBooster,
                    })
                );
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setStoreState({ loading: false }));
    };

export const getStoreDetailsParts =
    (params?: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setStoreState({ storeDetailsPartsLoading: true }));
        try {
            const response: any = await oauth(
                "STORE_DETAILS_PARTS",
                qs.stringify(params)
            )({
                method: "GET",
            });

            switch (response.RC) {
                case 200:
                    dispatch(
                        getStoreDetailPartSuccess({
                            storeSurcharge: response?.records?.surcharge_setting || {},
                            autoAcceptOrder: response?.records?.auto_accept_order_setting || {},
                            emt_setting: response?.records?.emt_setting || {},
                            discounted_upsell_settings: response?.records?.discounted_upsell_settings || {},
                        })
                    );
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setStoreState({ storeDetailsPartsLoading: false }));
    };

export const createStore =
    (params?: Record<string, any>, successCallBack = emptyFunction) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const intl = getIntl();
        try {
            const response: any = await oauth("CREATE_STORE")({
                method: "POST",
                body: JSON.stringify({
                    data: params,
                }),
            });
            switch (response.RC) {
                case 200:
                    dispatch(getStoreDetails());
                    toast(createMessage(intl.formatMessage({ id: "submit_review_success_msg" }), HiInformationCircle));
                    successCallBack();
                    break;
                default: {
                    const errMsg =
                        response?.msg ??
                        response?.message ??
                        intl.formatMessage({ id: "alert_failed_submit_for_review_failed" });
                    toast(createMessage(errMsg, HiInformationCircle));
                    break;
                }
            }
        } catch (e) {
            // Handle error
            console.error(e);
        }
    };

export const updateStoreDetails =
    (params?: Record<string, any> | any) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const intl = getIntl();
        if (params.payment_setting?.ppcp?.enabled === false) {
            params.payment_setting.ppcp.link = config.H5_URL;
        }
        try {
            const response: any = await oauth("STORE_DETAILS")({
                method: "POST",
                body: JSON.stringify({
                    data: params,
                }),
            });
            switch (response.RC) {
                case 200: {
                    dispatch(getStoreDetails());
                    const savedModel = params.changeLocation ?? "store_information";
                    toast(
                        createMessage(
                            `${intl.formatMessage({ id: savedModel })} ${intl.formatMessage({ id: "saved" })}`,
                            HiInformationCircle
                        )
                    );
                    break;
                }
                case 496:
                    Modal.error({
                        title: intl.formatMessage({ id: "error" }),
                        content: intl.formatMessage({ id: "third_party_courier_restaurant_error_message" }),
                    });
                    dispatch(setStoreState({ hasError: true }));
                    break;
                case 400:
                    //Not all the fields are filled in error
                    Modal.error({
                        title: intl.formatMessage({ id: "error" }),
                        content: intl.formatMessage({ id: "third_party_field_error_message" }),
                    });
                    dispatch(setStoreState({ hasError: true }));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const getOnBoardUser = (params?: Record<string, any> | any) => async (): Promise<any> => {
    const intl = getIntl();
    try {
        const response: any = await oauth("STORE_DETAILS")({
            method: "POST",
            body: JSON.stringify({
                data: params,
            }),
        });
        if (response.RC === 200) {
            return response.result;
        } else {
            Modal.error({
                title: intl.formatMessage({ id: "error" }),
                content: intl.formatMessage({ id: "fail_to_get_onboard_user_error_message" }),
            });
        }
    } catch (e) {
        // Handle error
    }
};

export const getOnBoardLink = (params?: Record<string, any> | any) => async (): Promise<any> => {
    const intl = getIntl();
    try {
        const response: any = await oauth("STORE_DETAILS")({
            method: "POST",
            body: JSON.stringify({
                data: params,
            }),
        });
        if (response.RC === 200) {
            return response.result;
        } else {
            Modal.error({
                title: intl.formatMessage({ id: "error" }),
                content: intl.formatMessage({ id: "fail_to_get_onboard_user_error_message" }),
            });
        }
    } catch (e) {
        // Handle error
    }
};

export const updateStoreDetailsParts =
    (params?: Record<string, any> | any) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const intl = getIntl();
        try {
            const response: any = await oauth("STORE_DETAILS_PARTS")({
                method: "POST",
                body: JSON.stringify({
                    data: params,
                }),
            });
            switch (response.RC) {
                case 200: {
                    dispatch(getStoreDetailsParts({ page: _.get(Object.keys(params), "0") }));
                    const savedModel = params.changeLocation ?? "store_information";
                    toast(
                        createMessage(
                            `${intl.formatMessage({ id: savedModel })} ${intl.formatMessage({ id: "saved" })}`,
                            HiInformationCircle
                        )
                    );
                    break;
                }
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const getStoreTypes =
    (query: any = {}) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const types: any = {};

            for (let i = 1; i <= 5; i++) {
                /* Loop through store categories 1-5 */
                if (i !== 2 && i !== 3) {
                    /* All except Group Sale (2) and Travel (3) */
                    query.c_id = i;
                    const response: any = await oauth(
                        "SFC",
                        qs.stringify(query)
                    )({
                        method: "GET",
                    });

                    switch (response?.RC) {
                        case 200:
                            types[i] = response?.records;
                            break;
                        default:
                            break;
                    }
                }
            }

            dispatch(getStoreTypesSuccess(types));
        } catch (e) {
            // Handle error
        }
    };

export const testLocalDeliveryConnection =
    (params?: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const intl = getIntl();
        try {
            const response: any = await fetch(`${config.DELIVERY_API_ROOT}${routes["DELIVERY_LOCAL_TEST"]}`, {
                method: "POST",
                body: JSON.stringify({
                    data: {
                        store_id: params?.store_id,
                        api_key: params?.api_key,
                    },
                }),
                headers: { "Content-Type": "application/json" },
            });
            let message;
            if (response?.status === 200) {
                message = "delivery_connection_success";
                dispatch(setThirdPartyDeliveryState({ name: "connected", value: true }));
            } else {
                message = "delivery_connection_failure";
            }
            toast(createMessage(intl.formatMessage({ id: message }), HiInformationCircle));
        } catch (e) {
            // Handle error
        }
    };

export const testDoordashDeliveryConnection =
    (params?: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const intl = getIntl();
        const actionValidationRequestValue = 8;
        try {
            const response: any = await oauth("DOORDASH_API_URL")({
                method: "POST",
                body: JSON.stringify({
                    data: {
                        action: actionValidationRequestValue,
                        developer_id: params?.doordash_dev_id,
                        key_id: params?.doordash_key_id,
                        signing_secret: params?.doordash_signing_secret,
                    },
                }),
                headers: { "Content-Type": "application/json" },
            });
            let message;
            if (response?.RC === 200) {
                message = "delivery_connection_success";
                dispatch(setThirdPartyDeliveryState({ name: "connected", value: true }));
            } else {
                message = "delivery_connection_failure";
            }
            toast(createMessage(intl.formatMessage({ id: message }), HiInformationCircle));
        } catch (e) {
            // Handle error
        }
    };

export const fetchDeliveryFeeEstimate =
    (params?: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const getEstimateAction = 2;
        try {
            const response: any = await oauth("DOORDASH_API_URL")({
                method: "POST",
                body: JSON.stringify({
                    data: {
                        action: getEstimateAction,
                        order_id: params?.order_id,
                        est_dt: params?.est_dt,
                    },
                }),
            });
            //let message;
            if (response?.RC === 200) {
                //message = "delivery_connection_success";
                dispatch(setDoordashDeliveryDetails({ name: "estDeliveryFee", value: response?.records?.fee }));
                dispatch(
                    setDoordashDeliveryDetails({ name: "estDeliveryTime", value: response?.records?.delivery_time })
                );
            } else {
                //message = "delivery_connection_failure";
                dispatch(setDoordashDeliveryDetails({ name: "estDeliveryFee", value: -1 }));
                dispatch(setDoordashDeliveryDetails({ name: "estDeliveryTime", value: "N/A" }));
            }
            //toast(createMessage(intl.formatMessage({ id: message }), HiInformationCircle));
        } catch (e) {
            //Handle error
            console.error(e);
        }
    };

export const createDoordashDeliveryOnAcceptedOrder =
    (params?: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const createDeliveryAction = 1;
        try {
            const response: any = await oauth("DOORDASH_API_URL")({
                method: "POST",
                body: JSON.stringify({
                    data: {
                        action: createDeliveryAction,
                        order_id: params?.order_id,
                        est_dt: params?.est_dt,
                    },
                }),
            });

            if (response?.RC === 200) {
                dispatch(setDoordashDeliveryDetails({ name: "doordashCreated", value: true }));
                dispatch(
                    actions.getLiveOrdersPartSuccess({
                        records: [response?.records] ?? [],
                        orderIds: response?.records?.ord_id,
                        updateFocus: _.get(params, "updateFocus", true),
                        outdatedId: params?.outdatedId,
                    })
                );
                params?.callBack();
            } else if (response?.RC === 456) {
                dispatch(setDoordashDeliveryDetails({ name: "showInsufficientFunds", value: true }));
            } else {
                dispatch(setDoordashDeliveryDetails({ name: "doordashCreatedFail", value: true }));
            }
        } catch (e) {
            //Handle error
        }
    };
export const createDoordashDelivery =
    (params?: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const createDeliveryAction = 1;
        try {
            const response: any = await oauth("ORDER_DELIVERY_STATUS")({
                method: "POST",
                body: JSON.stringify({
                    data: {
                        action: createDeliveryAction,
                        order_id: params?.order_id,
                        est_dt: params?.est_dt,
                        "3rd_party_delivery_request": 1,
                    },
                }),
            });

            if (response?.RC === 200) {
                dispatch(setDoordashDeliveryDetails({ name: "doordashCreated", value: true }));
                dispatch(
                    actions.getLiveOrdersPartSuccess({
                        records: [response?.order] ?? [],
                        orderIds: response?.order?.ord_id,
                        updateFocus: _.get(params, "updateFocus", true),
                        outdatedId: params?.outdatedId,
                    })
                );
                params?.callBack();
            } else if (response?.RC === 456) {
                dispatch(setDoordashDeliveryDetails({ name: "showInsufficientFunds", value: true }));
            } else {
                dispatch(setDoordashDeliveryDetails({ name: "doordashCreatedFail", value: true }));
            }
        } catch (e) {
            //Handle error
        }
    };

export const getDeliveryURL =
    (params: { delivery_id: string; openNewWindows?: (url: string) => void }) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const getStatusAction = 3;
            const { openNewWindows } = params;
            const response: any = await oauth("DOORDASH_API_URL")({
                method: "POST",
                body: JSON.stringify({
                    data: {
                        action: getStatusAction,
                        delivery_id: params?.delivery_id,
                    },
                }),
            });
            if (response?.RC === 200) {
                const url = _.get(response, "records.delivery_tracking_url") as string;
                openNewWindows?.(url);
                dispatch(setDoordashDeliveryDetails({ name: "delivery_url", value: url }));
            } else {
                dispatch(setDoordashDeliveryDetails({ name: "isCancelled", value: false }));
            }
        } catch (e) {
            //Handle error
        }
    };

export const cancelDoordashDelivery =
    (params?: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const cancelDeliveryAction = 4;
        try {
            const response: any = await oauth("DOORDASH_API_URL")({
                method: "POST",
                body: JSON.stringify({
                    data: {
                        action: cancelDeliveryAction,
                        delivery_id: params?.delivery_id,
                        order_id: params?.order_id,
                    },
                }),
            });
            if (response?.RC === 200) {
                dispatch(setDoordashDeliveryDetails({ name: "isCancelled", value: true }));
                params?.callBack();
            } else {
                dispatch(setDoordashDeliveryDetails({ name: "isCancelled", value: false }));
                dispatch(setDoordashDeliveryDetails({ name: "cancelFailure", value: true }));
            }
        } catch (e) {
            //Handle error
        }
    };
