import React, { Component } from "react";
import { Card, Row, Col, message, Dropdown, Menu } from "antd";
import _ from "lodash";
import _config from "config";
import _helper, { getDeliveryServiceProvider, getPaymentStatusString, showMarkAsPaidOrUnpaid } from "util/helper";
import "./_liveOrderCard.scss";
import { injectIntl, FormattedMessage } from "react-intl";
import classnames from "classnames";
import OrderDetail from "../order-detail";
import OrderItems from "../order-items";
import OrderTransactions from "../order-transactions";
import OrderComments from "../order-comments";
import { connect } from "react-redux";
import _storeHelper, { shouldShowRed, getCaculateRemainTime, isRestaurant, isGroupedOrder } from "../../helper";
import HeadRowButtons from "./live-order-head-row-buttons";
import { ClockCircleOutlined, DownOutlined, RightOutlined, EnvironmentOutlined } from "@ant-design/icons";
import _actions from "slices/live-order";
import {
    formatOrderId,
    getPaymentStringWithCreditCardInfo,
    formatPickupLocation,
    getOrderStatusText,
    allowEditPaymemnt,
    getShippingMethodNumber,
    getCommuteTime,
    getDeliveryStatusNumber,
    getOrderDate,
    getOrderTransactions,
    getDisplayTotal,
    getOrderStatusNumber,
} from "pages/order-history/helper";
import { getOrderId } from "pages/order-history/helper-order-list";
import { FiMoreVertical } from "react-icons/fi";
import { IoIosClose } from "react-icons/io";
import _orderHistoryActions from "slices/order-history";
import { MdRefresh } from "react-icons/md";
import cx from "classnames";
import { orderPaymentDialog } from "pages/order-history/page-components/order-payment-dialog";
import OrderMenus from "../order-menus";
import OrderListInfo from "../order-detail-grouped-order-list-info";
import LiveOrder from "util/model/live-order";
import { handlePrintReceipt } from "pages/order-history/order-print/order-detail-print-view";
import Collapse from "@material-ui/core/Collapse";
import config from "config";
import ShipmentTracking from "components/shipment-tracking";
import CopyArea from "components/copy-area";
import { store } from "../../../../app/store";

const REMAIN_TIME_COUNT = 30 * 1000; //in sec
const clockCircleStyle = {
    marginRight: 5,
};

class LiveOrderCard extends Component {
    state = {
        showDetail: false, // Check here to configure the default column
        remainsExpect: "",
        shouldExpectRed: false,
        remainsETA: "",
        shouldETARed: false,
        onEdit: false,
    };

    componentDidMount = () => {
        this.setUpCounter();
    };

    componentWillUnmount = () => {
        clearInterval(this.timeInteval);
    };

    componentDidUpdate(prevProps) {
        this.checkOrderUpdate(prevProps);
    }

    getPayStatus = () => _.get(this.props.order, "pay_status", config.PAY_STATUS_TO_NUMERIC.default);

    getOrderDeliveryStatus = () => getDeliveryStatusNumber(this.props.order);

    getOrderStatus = () => _.get(this.props.order, "ord_st", 0);

    getBodyStyle = () => {
        return this.props.bodyStyle ? this.props.bodyStyle : {};
    };

    checkOrderUpdate(prevProps) {
        const update = !_.isEqual(this.props.order, prevProps.order) && !_.isEmpty(this.props.order);
        if (update) this.setUpCounter();
    }

    setUpCounter() {
        this.calculateTime();
        if (this.timeInteval) {
            clearInterval(this.timeInteval);
        }
        this.timeInteval = setInterval(() => {
            this.calculateTime();
        }, REMAIN_TIME_COUNT);
    }

    calculateTime = (props = this.props) => {
        const { order } = props;
        const lan = props.lan;
        const store = this.getStoreData();
        const expectStart = order["exp_dt_start"];
        const etaTime = order["est_dt"];
        const remainsExpect = getCaculateRemainTime(expectStart, order, store);
        const shouldExpectRed = shouldShowRed(expectStart, store);
        const remainsETA = getCaculateRemainTime(etaTime, order, store);
        const shouldETARed = shouldShowRed(etaTime, store);
        const overDueTime = getCaculateRemainTime(etaTime ? etaTime : expectStart, order, store, true, lan);
        this.setState({
            remainsExpect,
            shouldExpectRed,
            remainsETA,
            shouldETARed,
            overDueTime,
        });
    };

    getStoreData = () => {
        const orderGid = _.get(this.props, "order.g_id", "");
        const storesData = _.get(this.props, "storesData", []);
        return _storeHelper.getStoreWithGid(orderGid, storesData);
    };

    handleOnPrint = () => {
        let { order } = this.props;
        let orderDetailInstance = new LiveOrder(order);

        handlePrintReceipt({
            orderDetail: orderDetailInstance,
            storeData: this.getStoreData(),
            orderStatus: "manual",
        });
    };

    isPending = () => {
        const deliveryStatus = getDeliveryStatusNumber(this.props.order);
        return deliveryStatus < 115; //115 store accepted
    };

    isProccessing = () => {
        const deliveryStatus = getDeliveryStatusNumber(this.props.order);
        return deliveryStatus >= 115; //115 store accepted
    };

    isThirdPartyCourier = () => {
        return (
            getDeliveryServiceProvider(this.props.store) ===
            config.PREFERRED_DELIVERY_METHODS_TO_NUMERIC.third_party_courier
        );
    };

    shouldShowShipmentTracking = () => {
        return (
            this.isThirdPartyCourier() &&
            _helper.isDelivery(this.props.shippingMethod) &&
            getOrderStatusNumber(this.props.order) >= config.ORDER_STATUS_MAPPING_TO_NUMERIC.processing
        );
    };

    renderCardTitle = () => {
        return (
            <div className="live-order-card-title">
                <div className="live-order-card-title-row-one">
                    {this.renderTitleMainInfo()}
                    {this.renderTitleOrderTimeExtraInfo()}
                </div>
                {this.renderTitleStatusOverDue()}
            </div>
        );
    };

    renderTitleMainInfo = () => {
        const { order } = this.props;

        //order id
        const orderId = formatOrderId(order["ord_id"]);

        //shipping methods
        const orderShpMemthod = getShippingMethodNumber(order);
        const storeType = _helper.getRealStoreType(_.get(this.getStoreData(), "store_flg", ""));
        const orderShpMemthodStr =
            _helper.reviseDeliveryMethod(storeType, _config.SHIPPING_MAPPING_TO_TEXT[orderShpMemthod]) || " ";
        const table = _.get(order, "tb_num", null);
        const withTable =
            table &&
            table !== 0 &&
            isRestaurant(this.getStoreData()) &&
            orderShpMemthod === _config.SHIPPING_MAPPING_TO_NUMERIC.eatin;
        return (
            <div className="card-title-left-info">
                {isGroupedOrder(this.props.order) ? null : <span>#{orderId}&nbsp;·&nbsp;</span>}
                <span>
                    <FormattedMessage id={orderShpMemthodStr} />
                    {withTable ? <span>&nbsp;·&nbsp;#{table}</span> : ""}
                </span>
                {this.props.fetchOrderDetail ? (
                    <span>
                        <MdRefresh
                            onClick={() => {
                                this.props.fetchOrderDetail();
                            }}
                            className={cx({
                                "live-order-card-detail-refresh": true,
                                spin: this.props.isDetailLoading,
                            })}
                        />
                    </span>
                ) : (
                    ""
                )}
            </div>
        );
    };

    renderTitleStatusOverDue = () => {
        const { order } = this.props;
        const store = this.getStoreData();
        //status
        const status = getOrderStatusText(order, store);

        //overdue
        const overDue = this.state.shouldETARed || this.state.shouldExpectRed ? this.state.overDueTime : "";

        return (
            <div style={{ color: this.isPending() ? "#ed2d29" : "black" }} className="card-title-status-string">
                <span>
                    <FormattedMessage id={status} />
                </span>
                {overDue ? <span style={{ color: "red" }}>&nbsp;·&nbsp;{overDue}</span> : ""}
            </div>
        );
    };

    renderTitleOrderTimeExtraInfo = () => {
        const { order, intl } = this.props;
        const str = (id) => intl.formatMessage({ id: id });
        const isPaid = this.getPayStatus() === config.PAY_STATUS_TO_NUMERIC.paid;
        const formatedDate = getOrderDate(order, this.getStoreData());

        const printButtonClick = () => this.handleOnPrint();

        const showCancelModal = () => {
            const orderId = getOrderId(order);
            this.props.setLiveOrderState({
                modelController: `${orderId}:cancelModal`,
            });
        };

        const showCompleteModal = () => {
            const orderId = getOrderId(order);
            this.props.setLiveOrderState({
                modelController: `${orderId}:completeModal`,
            });
        };

        const showUpdatePaymentModal = () => {
            orderPaymentDialog({
                order: order,
                updateOrderPayment: (payload) => this.props.updateOrder(payload),
                orderStore: this.getStoreData(),
            });
        };

        const renderMoreIconMenus = () => {
            let data = [
                {
                    label: "print",
                    onClick: printButtonClick,
                },
                {
                    label: "complete",
                    onClick: showCompleteModal,
                },
                {
                    label: "cancel_order",
                    onClick: showCancelModal,
                },
            ];

            if (allowEditPaymemnt(order) && !isGroupedOrder(order)) {
                data.push({
                    label: "edit_payment",
                    onClick: showUpdatePaymentModal,
                });
            }

            if (showMarkAsPaidOrUnpaid(order)) {
                data.push({
                    label: isPaid ? "mark_as_unpaid" : "mark_as_paid",
                    onClick: () => {
                        isPaid ? this.props.markAsUnPaid(getOrderId(order)) : this.props.markAsPaid(getOrderId(order));
                    },
                });
            }

            return (
                <Menu>
                    {data.map((menu) => (
                        <Menu.Item key={menu.label} className="more-drop-down-text" onClick={() => menu.onClick()}>
                            {str(menu.label)}
                        </Menu.Item>
                    ))}
                </Menu>
            );
        };

        const renderRightIcon = () => {
            if (this.props.isOrderDetail) {
                return (
                    <span
                        onClick={() =>
                            this.props.setOrderDetailState({
                                showOrderDetail: false,
                                orderDetailInstance: {},
                            })
                        }
                        className="more-icon close-icon"
                    >
                        <IoIosClose />
                    </span>
                );
            } else {
                return (
                    <span className="more-icon">
                        <FiMoreVertical />
                    </span>
                );
            }
        };

        const renderOrderDate = () => {
            return (
                <div className="live-order-card-extra">
                    <span>
                        <ClockCircleOutlined height={15} width={15} style={clockCircleStyle} />
                    </span>
                    <span className="live-order-card-extra-order-time">{formatedDate}</span>
                    {renderRightIcon()}
                </div>
            );
        };

        return (
            <Dropdown overlay={renderMoreIconMenus()} placement="bottomRight" disabled={this.props.isOrderDetail}>
                {renderOrderDate()}
            </Dropdown>
        );
    };

    renderOrderStore = () => {
        const { lan } = this.props;
        const storesData = _.get(this.props, "storesData", []);
        const storeData = this.getStoreData();
        const nameObj = _.get(storeData, "general_info.store_nm", {});
        const showStoreInfo = storesData && storesData.length > 1;
        return showStoreInfo ? (
            <Row className="content-store-row">
                <div className="live-order-store-name">
                    <b>{_helper.getTransString(nameObj, lan)}</b>
                </div>
            </Row>
        ) : (
            ""
        );
    };

    renderContentHeadRow = () => {
        const { order, withoutButton } = this.props;
        const orderId = _.get(order, "ord_id");
        const buttonWidth = withoutButton ? 0 : 33;
        const expectStart = order["exp_dt_start"];
        const etaTime = order["est_dt"] ? order["est_dt"] : "";
        const estimatedArrival = _.get(order, "est_customer_ready_dt");

        const renderReqTime = () => {
            const { remainsExpect, shouldExpectRed } = this.state;
            const expectEnd = order["exp_dt_end"];
            const expectFull = _helper.getFullDate(expectStart, expectEnd, {
                timeFormat: "hh:mmA",
            });
            const etaArray = _helper.isString(expectFull) ? expectFull.split("@") : [];
            if (expectStart)
                return (
                    <div key={`req-time-${orderId}`} style={getContentStyle()}>
                        <div className="content-req-time">
                            <div className="content-head-row-col-title">
                                <b>
                                    <span>
                                        <FormattedMessage id="req_time_short" />
                                    </span>
                                    <span style={{ color: "red" }}> {remainsExpect}</span>
                                </b>
                            </div>
                            <div className="content-head-row-col-content">
                                <b
                                    className={classnames({
                                        "red-text": shouldExpectRed,
                                        "orignal-text": !shouldExpectRed,
                                    })}
                                >
                                    {etaArray.length === 2
                                        ? etaArray.map((time, i) => (
                                              <div key={`req-time-${orderId}-${i}`}>{time.trim()}</div>
                                          ))
                                        : expectFull}{" "}
                                </b>
                            </div>
                        </div>
                    </div>
                );
        };

        const renderETA = () => {
            const { remainsETA, shouldETARed } = this.state;

            const etaTimeStr = _helper.utcToLocal(etaTime, {
                type: "calendar",
                local: false,
                asap: true,
                timeFormat: "hh:mmA",
            });
            const etaArray = _helper.isString(etaTimeStr) ? etaTimeStr.split("@") : [];
            if (etaTime)
                return (
                    <div
                        style={getContentStyle()}
                        onClick={() => {
                            this.props.setLiveOrderState({
                                modelController: `${orderId}:processingModal`,
                            });
                        }}
                    >
                        <div className="content-eta-time">
                            <div className="content-head-row-col-title">
                                <b>
                                    <FormattedMessage id="estimated_delivery_short" />
                                    <span style={{ color: "red" }}> {remainsETA}</span>
                                </b>
                            </div>
                            <div className="content-head-row-col-content">
                                <b
                                    className={classnames({
                                        "red-text": shouldETARed,
                                        "orignal-text": !shouldETARed,
                                    })}
                                >
                                    {" "}
                                    {etaArray.map((time, i) => (
                                        <div key={`ETA-${orderId}-${i}`}>{time.trim()}</div>
                                    ))}{" "}
                                </b>
                            </div>
                        </div>
                    </div>
                );
        };

        const renderCommuteTime = () => {
            const commuteTime = getCommuteTime(order);
            return (
                <div
                    key={`commute-time-${orderId}`}
                    style={getContentStyle()}
                    onClick={() => {
                        this.props.setLiveOrderState({
                            modelController: `${orderId}:processingModal`,
                        });
                    }}
                >
                    <div className="content-eta-time">
                        <div className="content-head-row-col-title">
                            <b>
                                <FormattedMessage id="commute_time" />
                            </b>
                        </div>
                        <div className="content-head-row-col-content">
                            <b
                                className={classnames({
                                    "red-text": false,
                                    "orignal-text": true,
                                })}
                            >
                                {" "}
                                {commuteTime} <FormattedMessage id="mins" />{" "}
                            </b>
                        </div>
                    </div>
                </div>
            );
        };

        const renderEsitmatedArrivalTime = () => {
            const etaTimeStr = _helper.utcToLocal(estimatedArrival, {
                type: "calendar",
                local: false,
                asap: true,
                timeFormat: "hh:mmA",
            });
            const etaArray = _helper.isString(etaTimeStr) ? etaTimeStr.split("@") : [];
            if (estimatedArrival)
                return (
                    <div
                        style={getContentStyle()}
                        onClick={() => {
                            this.props.setLiveOrderState({
                                modelController: `${orderId}:processingModal`,
                            });
                        }}
                    >
                        <div className="content-eta-time">
                            <div className="content-head-row-col-title">
                                <b>
                                    <FormattedMessage id="esitmated_arrival" />
                                </b>
                            </div>
                            <div className="content-head-row-col-content">
                                <b
                                    className={classnames({
                                        "red-text": false,
                                        "orignal-text": true,
                                    })}
                                >
                                    {" "}
                                    {etaArray.map((time, i) => (
                                        <div key={`esitmated-arrival-time-${orderId}-${i}`}>{time.trim()}</div>
                                    ))}{" "}
                                </b>
                            </div>
                        </div>
                    </div>
                );
        };

        const renderButtons = () => {
            return (
                <div
                    style={{
                        width: `${buttonWidth}%`,
                        display: buttonWidth ? "initial" : "none",
                    }}
                >
                    <HeadRowButtons orderStore={this.getStoreData()} {...this.props} />
                </div>
            );
        };

        //determin what should be render
        const data = [];
        if (expectStart) data.push(renderReqTime);
        if (this.isProccessing() && etaTime) data.push(renderETA);
        if (_helper.isDelivery(this.props.shippingMethod) && !this.isThirdPartyCourier()) data.push(renderCommuteTime);
        if (_helper.isDelivery(this.props.shippingMethod) && this.isProccessing() && estimatedArrival)
            data.push(renderEsitmatedArrivalTime);

        const getContentStyle = () => {
            const maxAllowSize = withoutButton ? 4 : 2;
            const renderArrayLength = data.length;
            const boxesNum = renderArrayLength > maxAllowSize ? maxAllowSize : renderArrayLength;
            return {
                float: "left",
                width: `${100 / boxesNum}%`,
            };
        };

        if (data.length || buttonWidth)
            return (
                <div className="content-head-row">
                    <div
                        className="content-head-row-content"
                        style={{
                            width: `${100 - buttonWidth}%`,
                        }}
                    >
                        {data.map((fun) => fun())}
                    </div>
                    {renderButtons()}
                </div>
            );
    };

    renderContentSecRow = () => {
        const { order, intl, shippingMethod } = this.props;
        const str = (id) => intl.formatMessage({ id: id });
        // "delivery": "1",
        // "pickup": "2",
        // "eatin": "3",
        const getShippingAddress = () => {
            if (_helper.isDelivery(shippingMethod)) {
                return _.get(order, "shipping_adr") ? _.get(order, "shipping_adr") : {};
            }
            const pickupLocation = _.get(order, "pickup_location");
            if (_helper.isPickup(shippingMethod) && pickupLocation) {
                return formatPickupLocation(pickupLocation);
            }
            return {};
        };

        const isPickupLocation = () => {
            if (_helper.isDelivery(shippingMethod)) {
                return false;
            }
            const pickupLocation = _.get(order, "pickup_location");
            if (_helper.isPickup(shippingMethod) && pickupLocation) {
                return true;
            }
            return false;
        };

        const getLabel = () => {
            const pickupLocation = _.get(order, "pickup_location");
            const deliveryZoneName = _.get(order, "delivery_zone.name");
            if (_helper.isPickup(shippingMethod) && pickupLocation) {
                return "pickup_location";
            }
            if (_helper.isDelivery(shippingMethod) && deliveryZoneName) {
                return deliveryZoneName;
            }
        };

        const addressObj = getShippingAddress();
        const customerFullName = _.get(order, "c_name");
        const customerPhone = _.get(order, "c_tn") ? _helper.formatPhone(_.get(order, "c_tn")) : "";
        const googleAddress = _helper.formatAddress(addressObj, {
            size: 1,
            compactWith: " ",
            without: ["buzz", "unit"],
        });
        // Display address without region and country if the store is a restaurant
        const formattedAddress = isRestaurant(this.getStoreData())
            ? _helper.formatAddress(addressObj, {
                  without: ["region", "cntry"],
              })
            : _helper.formatAddress(addressObj);

        const openGoogleMap = () => {
            if (!_.isEmpty(this.props.order.tracking_url)) {
                let url = this.props.order.tracking_url;
                window.open(url);
            } else {
                if (googleAddress.length) {
                    let url = `https://www.google.com/maps/search/?api=1&query=${googleAddress[0]}`;
                    window.open(url);
                } else {
                    message.error(str("unabled_navigate_location_missing"));
                }
            }
        };

        const renderLabel = () => {
            return getLabel() ? (
                <div className="content-head-row-col-title">
                    <b>
                        <FormattedMessage id={getLabel()} />
                    </b>
                </div>
            ) : (
                ""
            );
        };

        const renderNameAndPhone = () => {
            return (
                <Col span={!_.isEmpty(addressObj) ? 11 : 24}>
                    <CopyArea
                        copyData={customerFullName}
                        messageId="customer_name"
                        iconPosition="end"
                        className="content-sec-row-col-content customer-name"
                    >
                        {customerFullName}
                    </CopyArea>

                    {customerPhone ? (
                        <CopyArea
                            className="phone-row content-sec-row-col-content"
                            copyData={_.get(order, "c_tn")}
                            messageId="phone_number"
                            iconPosition="end"
                        >
                            <a className="phone-text" href={`tel:${customerPhone}`}>
                                {customerPhone}
                            </a>
                        </CopyArea>
                    ) : (
                        ""
                    )}
                </Col>
            );
        };

        const renderAddress = () => {
            return (
                <Col span={!_.isEmpty(addressObj) ? 13 : 0}>
                    <div onClick={() => openGoogleMap()} className="content-sec-row-address-col">
                        <span className="address-icon">
                            <EnvironmentOutlined />
                        </span>
                        <span>
                            {renderLabel()}
                            {isPickupLocation() ? (
                                <div>
                                    <div> {_.get(addressObj, "fn") ? _.get(addressObj, "fn") : ""}</div>
                                    <div>
                                        {_.get(addressObj, "tn") ? _helper.formatPhone(_.get(addressObj, "tn")) : ""}
                                    </div>
                                </div>
                            ) : null}
                            {formattedAddress.map((str, i) => (
                                <div className="content-sec-row-col-content " key={`${str}-${i}`}>
                                    {str}
                                </div>
                            ))}
                        </span>
                    </div>
                </Col>
            );
        };

        return (
            <div className="border-bottom content-sec-row">
                <Row style={{ width: "100%" }} gutter={8}>
                    {renderNameAndPhone()}
                    {renderAddress()}
                </Row>
            </div>
        );
    };

    renderContentOrderTotalRow = () => {
        const { order } = this.props;
        const orderId = _.get(this.props, "order.ord_id");
        const payStatusStr = getPaymentStatusString(this.getPayStatus());

        const getController = () => _.get(this.props, `state.expansionControllers.${orderId}`, {});
        const showDetail = () => _.get(getController(), `showDetail`, false);
        const toggleShowDetail = () => {
            let expansionControllers = _.cloneDeep(_.get(this.props, "state.expansionControllers", {}));
            let controller = _.cloneDeep(getController());
            controller.showDetail = !showDetail();
            expansionControllers[orderId] = controller;
            this.props.setLiveOrderState({
                expansionControllers: expansionControllers,
                activeId: orderId,
            });
        };
        const currency = store.getState().store?.storeCurrencyAndPricePlan?.store_currency ?? "CAD";
        const total = getDisplayTotal(order);

        //items total
        const items = Array.isArray(_.get(order, "ord_itm")) ? _.get(order, "ord_itm") : [];
        const itemTotal = items.reduce((acc, item) => {
            acc += item.cnt;
            return acc;
        }, 0);
        return (
            <div>
                <div onClick={() => toggleShowDetail()} className="order-sum-row">
                    <div>
                        <span className="order-total">{_helper.getCurrencyFormattedString(total, currency)}</span>
                        {isGroupedOrder(this.props.order) ? null : (
                            <span style={{ color: "black" }}>
                                <b>&nbsp;·&nbsp;</b>
                                {_helper.isPPCP3dsOrder(order) ? <b id="lock-icon">&#128274;</b> : null}
                                <b>{getPaymentStringWithCreditCardInfo(order)}</b>
                                {payStatusStr && (
                                    <b>
                                        &nbsp;·&nbsp;
                                        <FormattedMessage id={payStatusStr} />
                                    </b>
                                )}
                            </span>
                        )}
                        <span>
                            <b>&nbsp;·&nbsp;</b>
                            <b>
                                <FormattedMessage
                                    id="total_item_count"
                                    values={{
                                        total: Number.isInteger(itemTotal) ? itemTotal : 0,
                                    }}
                                />
                            </b>
                        </span>
                    </div>
                    <div>{showDetail() ? <DownOutlined /> : <RightOutlined />}</div>
                </div>
                {this.renderOrderDetail(showDetail())}
                <div></div>
            </div>
        );
    };

    renderOrderDetail(showDetail = true) {
        let { order, lan } = this.props;
        let currency = order["currency"] ? order["currency"] : "CAD";
        return (
            <Collapse in={showDetail}>
                <div className="order-detail-section">
                    <OrderListInfo order={order} />
                    <OrderMenus order={order} orderStore={this.getStoreData()} />
                    <OrderItems
                        lan={lan}
                        order={order}
                        orderItems={order["ord_itm"]}
                        orderStore={this.getStoreData()}
                        storeCurrency={currency}
                    />
                    <OrderDetail order={order} {...order} />
                </div>
            </Collapse>
        );
    }

    renderSourceInfo = () => {
        const { order } = this.props;
        const str = (id) => this.props.intl.formatMessage({ id });

        //set display info
        const getSouceInfo = () => {
            const DISPLAY_INFO = ["os", "browser", "ip_address"];
            let infoFullStr = "";
            DISPLAY_INFO.map((key) => {
                const label = str(key);
                const value = _.get(order, key);
                if (value) {
                    const info = `${label}:${value}`;
                    infoFullStr += infoFullStr ? `, ${info}` : info;
                }
                return key;
            });
            return infoFullStr;
        };

        if (getSouceInfo())
            return (
                <div className="order-detail-source-info">
                    <span className="order-detail-source-info-label">
                        <FormattedMessage id="order_from" />
                    </span>
                    <span>{getSouceInfo()}</span>
                </div>
            );
    };

    renderOrderComments = () => {
        const { order } = this.props;
        return (
            <div>
                <OrderComments orderStore={this.getStoreData()} order={order} />
            </div>
        );
    };

    renderOrderPaymentTransactions = () => {
        const { order } = this.props;
        const transactions = getOrderTransactions(order);
        return !_.isEmpty(transactions) && <OrderTransactions transactions={transactions} order={order} />;
    };

    render() {
        const orderStatus = getOrderStatusNumber(this.props.order);

        return (
            <div className="live-order-card-wrapper">
                <Card bodyStyle={this.getBodyStyle()} bordered={false} hoverable={true} title={this.renderCardTitle()}>
                    {this.renderOrderStore()}
                    {this.renderContentHeadRow()}
                    {this.renderContentSecRow()}
                    {this.renderContentOrderTotalRow()}
                    {this.renderSourceInfo()}
                    {this.renderOrderComments()}
                    {this.renderOrderPaymentTransactions()}
                    {this.shouldShowShipmentTracking() ? (
                        <ShipmentTracking orderStatus={orderStatus} orderId={getOrderId(this.props.order)} />
                    ) : null}
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    lan: _.get(state, "setting.lan", "en"),
    storesData: _.get(state, "managedStores.activeStores", []),
    state: _.get(state, "liveOrders", {}),
    store: _.get(state, "store", {}),
    shippingMethod: _.get(ownProps, "order.shp_mtd", null),
});

const mapDispatchToProps = {
    setLiveOrderState: _actions.setState,
    setOrderDetailState: _orderHistoryActions.setState,
    updateOrder: (data) => _actions.updateOrder({ data }),
    markAsPaid: _actions.markAsPaid,
    markAsUnPaid: _actions.markAsUnPaid,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LiveOrderCard));
