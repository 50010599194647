import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "ppcpOnboard",
    initialState: {
        isOnboardLoaded: 0,
    },
    reducers: {
        setIsOnboardLoaded: (state, action) => {
            state.isOnboardLoaded = action.payload;
        },
    },
});

export const { setIsOnboardLoaded } = slice.actions;

export const ppcpOnboard = slice.reducer;
