import helper, { metersToKm, metersToMiles } from "util/helper";
import _ from "lodash";
import config from "config";
import { getStorePrepTime, getStorePrepOrderThreshold, getStoreTimeZone, isRestaurant } from "pages/liveorder/helper";
import moment from "moment";
import locale, { getIntl } from "locale";
import { getPaymentMethodStr } from "./helper-order-list";

export const getPaymentStringWithCreditCardInfo = (order) => {
    const str = (id) => getIntl().formatMessage({ id });
    //payment
    const paymentMethodStr = getPaymentMethodStr(order);
    //ccinfo
    const cc = _.get(order, "cc", {});
    const creditCardTypeStr = config.CREDIT_CARD_TYPE_MAP[cc.type];
    const creditCardNum = cc.num;
    var result = `${paymentMethodStr ? str(paymentMethodStr) : ""}`;
    if (creditCardTypeStr && creditCardNum) {
        // const showPaymentStr = !paymentMethodStr.includes("credit_card");
        const showPaymentStr = !(
            paymentMethodStr.includes("credit_card") ||
            result === "Credit Card" ||
            result === "paypal_complete_payments_paypal_with_3ds"
        );
        const withPaymentTypeStr = `${str(paymentMethodStr)} (${str(creditCardTypeStr)} #${creditCardNum})`;
        const normalStr = `${str(creditCardTypeStr)} #${creditCardNum}`;
        result = showPaymentStr ? withPaymentTypeStr : normalStr;
    }
    return result;
};

export const formatOrderId = (orderId) => {
    const ORDER_NUMBER_LENGTH = 4;
    const id = helper.isString(orderId) ? orderId : " ";
    const dashLength = helper.isString(orderId) ? orderId.replace(/[^-]/g, "").length : 0;
    const dashRightLength = helper.isString(orderId) ? _.get(orderId.split("-"), "1", []).length : 0;
    const subStringLength = ORDER_NUMBER_LENGTH + dashLength + dashRightLength;
    return id.length <= subStringLength ? id : id.substring(id.length - subStringLength);
};

export const getOrderDate = (order, store) => {
    const time = _.get(order, "ord_dt");
    return helper.utcToLocal(time, {
        type: "calendar",
        asap: true,
        timeFormat: "hh:mmA",
        timeZone: getStoreTimeZone(store),
    });
};

export const getDeliveryStatusNumber = (order) => {
    return String(_.get(order, "delivery_status"));
};

export const getShortDeliveryStatus = (order, store) => {
    const deliveryStatus = String(_.get(order, "delivery_status"));
    const listMap = config.ORDER_SHORTEN_LIST_STATUS_MAPPING;
    const result = Object.keys(listMap).find((key) => {
        const includedStatuses = listMap[key];
        return includedStatuses.includes(deliveryStatus);
    });
    if (result === "serve") {
        return isRestaurant(store) ? "serve_restaurant" : "serve_others";
    }
    return result;
};

export const getDeliveryStatus = (order, store) => {
    const deliveryStatus = getDeliveryStatusNumber(order);
    const result = config.ORDER_DELIVERY_STATUS_MAPPING[deliveryStatus];
    if (String(result) === "serve") {
        return isRestaurant(store) ? "serve_restaurant" : "serve_others";
    }
    return result;
};

export const getOrderStatusNumber = (order) => {
    return String(_.get(order, "ord_st"));
};

export const getOrderStatus = (order) => {
    const orderStatus = getOrderStatusNumber(order);
    const result = config.ORDER_STATUS_MAPPING[orderStatus];
    return result;
};

export const getOrderStatusText = (order, store) => {
    const deliveryStatusText = getDeliveryStatus(order, store);
    const orderStatusText = getOrderStatus(order);
    return deliveryStatusText ? deliveryStatusText : orderStatusText ? orderStatusText : " ";
};

export const getShippingMethodNumber = (order) => {
    return _.get(order, "shp_mtd", config.SHIPPING_MAPPING_TO_NUMERIC.delivery);
};

export const getDefaultETA = (order, store) => {
    const expectedTime = _.get(order, "exp_dt_start");
    const expectedActualTime = moment(expectedTime).isValid() ? moment(expectedTime) : moment();
    const commuteTime = getCommuteTime(order);
    const expectedTimeWithCommuteTime = expectedActualTime.subtract(commuteTime, "minutes");
    //store default eta
    const method = getShippingMethodNumber(order);
    const storePrepTime = getStorePrepTime(store, method);
    const defaultETA = moment().add(storePrepTime, "minutes");
    //if exp is later then default
    if (expectedTimeWithCommuteTime.isAfter(defaultETA)) {
        return expectedActualTime;
    }
    return defaultETA;
};

export const isScheduledOrder = (order, store) => {
    const expectedTime = _.get(order, "exp_dt_start");
    const storePrepTime = getStorePrepOrderThreshold(store);
    const scheduleCutOff = moment().add(storePrepTime, "minutes");
    if (moment(expectedTime).isValid() && moment(expectedTime).isAfter(scheduleCutOff)) {
        return true;
    }
    return false;
};

export const getOrderTypeText = (order, store, lan) => {
    const storeFlg = _.get(store, "store_flg", "");
    const storeType = helper.getRealStoreType(storeFlg);
    const isRestruant = Number(storeType) === 1;
    const shippingMethod = getShippingMethodNumber(order);
    const table = _.get(order, "table_num") ? _.get(order, "table_num") : _.get(order, "tb_num");
    const withTable = table && Number(table) !== 0 && isRestruant && helper.isEatIn(shippingMethod);
    const realMethod = helper.reviseDeliveryMethod(storeType, config.SHIPPING_MAPPING_TO_TEXT[shippingMethod]);
    const translatedStr = realMethod ? locale.getIntlMessages(lan, "common")[realMethod] : "";
    return `${translatedStr}${withTable ? ` · #${table}` : ""}`;
};

export const formatPickupLocation = (location) => {
    const pickupAddress = _.get(location, "address", {});
    return {
        ...pickupAddress,
        tn: _.get(location, "phone", ""),
        fn: _.get(location, "name", ""),
        zipcd: _.get(pickupAddress, "postal_code"),
        cntry: _.get(pickupAddress, "country"),
    };
};

export const getRemainTime = (time, store) => {
    const timeZone = getStoreTimeZone(store);
    const nowTimeZoneStr = moment().tz(timeZone).format("YYYY-MM-DD HH:mm:ss");
    const now = moment(nowTimeZoneStr);
    const eta = moment(time);
    let toReturn = "";
    if (helper.isString(time) && !time.includes("2000-01-01") && eta.isValid()) {
        const duration = moment.duration(eta.diff(now));
        if (Math.abs(duration.asHours()) >= 24) {
            const hours = Math.abs(duration.asHours());
            const days = parseInt(hours / 24);
            const remainHours = parseInt(hours % 24);
            toReturn = `${days}d${remainHours ? ` ${remainHours}h` : ""}`;
        } else if (Math.abs(duration.asMinutes()) >= 60) {
            const mins = Math.abs(duration.asMinutes());
            const hours = parseInt(mins / 60);
            const remainMins = parseInt(mins % 60);
            toReturn = `${hours}h${remainMins ? ` ${remainMins}m` : ""}`;
        } else if (Math.abs(duration.asMinutes()) > 1) {
            toReturn = Math.abs(duration.asMinutes().toFixed(0)) + "m";
        } else if (Math.abs(duration.asSeconds()) > 0) {
            toReturn = "1m";
        }
    }
    return toReturn;
};

export const requestUtensilNumber = (order) => {
    return String(_.get(order, "require_utensil"), 1);
};

export const requestUtensil = (order) => {
    return requestUtensilNumber(order) === "1";
};

export const orderShowRequireUtensil = (store, order) => {
    const restaurant = isRestaurant(store);
    const orderShow = requestUtensilNumber(order) !== "-1";
    return restaurant && orderShow;
};

export const allowBulkUpdate = (order) => {
    const status = getOrderStatusNumber(order);
    const statusMap = config.ORDER_STATUS_MAPPING_TO_NUMERIC;
    return [statusMap.pending, statusMap.processing].includes(status);
};

export const isOnlinePay = (order) => {
    return String(_.get(order, "is_online_pay", 0)) === "1";
};

export const allowEditPaymemnt = (order) => {
    //http://project.goopter.com/issues/12276
    const doNotAllow = isOnlinePay(order) && Number(_.get(order, "ttl_due")) === 0;
    return !doNotAllow;
};

export const getCommuteTime = (order) => {
    const commuteValue = Number(_.get(order, "commute_time")) ? parseFloat(_.get(order, "commute_time")).toFixed(0) : 0;
    const shippingMethod = getShippingMethodNumber(order);
    return helper.isDelivery(shippingMethod) ? commuteValue : 0;
};

export const DISTANCE_UNIT_MAP = {
    km: "1",
    mi: "2",
};
export const getFormattedDistance = (order) => {
    const distanceInM = _.get(order, "distance", 0);
    const orderUnit = String(_.get(order, "distance_unit"));
    const unit = Object.values(DISTANCE_UNIT_MAP).includes(orderUnit) ? orderUnit : DISTANCE_UNIT_MAP.km;
    const unitStr = helper.getKeyByValue(DISTANCE_UNIT_MAP, unit);
    let distance = 0;
    if (unit === DISTANCE_UNIT_MAP.km) {
        distance = metersToKm(distanceInM).toFixed(2);
    } else if (unit === DISTANCE_UNIT_MAP.mi) {
        distance = metersToMiles(distanceInM).toFixed(2);
    }
    if (distance < 1) {
        return `<1${unitStr}`;
    } else {
        return `${distance}${unitStr}`;
    }
};

export const ORDER_DELIVERY_STATUS_ACTIONS = {
    to_accept: 1, //115,
    to_ready_for_pickup: 2, //130
    to_delivering: 3, //260,
    to_preparing: 4, //120
    to_served: 5, //135
};
export const ORDER_DETAIL_ACTIONS = {
    to_processing: 1,
    to_complete: 2,
    to_cancel: 3,
    refund: 4,
    change_payment: 5,
    add_surcharge: 6,
    update_tips: 7,
    print_status: 8,
    to_closed: 9,
    to_served: 10,
};

export const handlePrintReceipt = () => {
    const mywindow = window.open("", "PRINT", "height=600,width=800");
    mywindow.document.write("<html><head><title>" + document.title + "</title>");
    mywindow.document.write("</head><body >");

    mywindow.document.write("</body></html>");

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
};

export const getItemOptionsArray = (options, lan) => {
    let fullOptions = [];
    Array.isArray(options) &&
        options.forEach((option) => {
            const subOptions = Array.isArray(option.opts) ? option.opts : [];
            //combined all with same id
            let optItems = {};
            subOptions.forEach((optItem) => {
                const qty = _.get(optItems, `${optItem.id}.qty`, 0);
                const newQty = _.get(optItem, `qty`, 0) || 1;
                optItems[optItem.id] = {
                    ...optItem,
                    qty: qty + newQty,
                };
            });
            //put it to string
            let optItemStr = "";
            Object.values(optItems).forEach((item) => {
                const itemName = helper.getTransString(item.nm, lan);
                const itemStr = item.qty > 1 ? `${item.qty} x ${itemName}` : itemName;
                optItemStr += optItemStr ? `, ${itemStr}` : itemStr;
            });
            if (!_.isEmpty(optItems)) {
                fullOptions.push({
                    id: option.id,
                    nm: helper.getTransString(option.nm, lan),
                    optStr: optItemStr,
                });
            }
        });
    return fullOptions;
};

export const getOrderMenus = (order) => {
    const orderMenus = _.get(order, "menu_ids");
    const orderMenusArray = Array.isArray(orderMenus) ? orderMenus : [];
    return _.uniqBy(orderMenusArray, "id");
};

export const getOrderMenuString = (order, lan) => {
    const menus = getOrderMenus(order);
    let result = "";
    menus.forEach((menu) => {
        const name = (menu = helper.getTransString(_.get(menu, "name"), lan));
        result += name ? (result ? `, ${name}` : name) : "";
    });
    return result;
};

export const getGroupProductId = (order) => {
    return _.get(order, "gp_id");
};

export const getOrderRewardPoints = (order) => {
    return Number(_.get(order, "pts_reward")) ? _.get(order, "pts_reward") : 0;
};

export const getDisplayTotal = (order) => {
    return _.get(order, "display_amt", 0) || 0;
};

export const getOrderRewardBalance = (order) => {
    return Number(_.get(order, "pts_bal")) ? _.get(order, "pts_bal") : 0;
};

export const getOrderTransactions = (order) => {
    const transactions = _.get(order, "txn_history", []);
    return Array.isArray(transactions) ? transactions : [];
};

export const getDefaultOpts = (product, lan) => {
    const options = Array.isArray(_.get(product, "opt")) ? _.get(product, "opt") : [];
    let result = [];
    options.forEach((option) => {
        const min = _.get(option, "min", 0);
        const subOpitons = _.get(option, "opts", []);
        const subOptionsLength = subOpitons.length;
        const max = subOptionsLength > min ? min : subOptionsLength;
        let subOpts = [];
        for (var i = 0; i < max; i++) {
            const currentSubOpt = subOpitons[i];
            if (currentSubOpt) {
                subOpts.push({
                    id: currentSubOpt.id,
                    nm: { [lan]: currentSubOpt.nm },
                    pcIn: currentSubOpt.pc, //price for internal calculation only
                });
            }
        }
        if (!_.isEmpty(subOpts)) {
            result.push({
                id: option.id,
                nm: { [lan]: option.nm },
                opts: subOpts,
            });
        }
    });
    return result;
};
