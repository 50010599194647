import React from "react";
import _ from "lodash";
import _config from "../../../../config";
import _locale from "../../../../locale";
import _helper, { formatCurrency } from "../../../../util/helper";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { Col, Row } from "antd";

const TIME_FORMAT = "HH:mm";

function SalesOperationReport(props: any = {}): JSX.Element {
    const intl = useIntl();
    const getFirstColumnStr = (value: any, length: any = undefined) => {
        return value.length >= length ? value.substr(0, length) : value;
    };

    function formatToLocalStr(id: any) {
        const { lan } = props;
        //@ts-ignore
        return _locale.getIntlMessages(lan, "sales-page")[id];
    }

    function formatToPaymentCode(code: any) {
        const codeMethodMap: any = _config.PAYMENT_SHORTCODE_MAPPING;
        return codeMethodMap[code] ? codeMethodMap[code] : codeMethodMap["cashondelivery"];
    }

    function formatToPaymentLocal(code: any) {
        const localStr = formatToLocalStr(code);
        return localStr ? localStr : formatToLocalStr("cash");
    }

    function isStaffReport() {
        const { rpTitle } = props;
        return rpTitle.toLowerCase().includes("staff");
    }

    function createTopHeader(rows: any) {
        const { includeTips, reportData } = props;
        const sales = reportData["sales"];
        const isNameExist =
            Object.keys(sales)[0] && sales[Object.keys(sales)[0]] && sales[Object.keys(sales)[0]]["staff"];
        rows.push({
            topBorder: "border-line-solid",
            col: [
                {
                    class: "text-align-start",
                    value: isNameExist ? formatToLocalStr("name_date") : formatToLocalStr("date"),
                },
                {
                    value: formatToLocalStr("total_cnt"),
                },
                {
                    value: formatToLocalStr("total_amt"),
                },
                {
                    value: formatToLocalStr("tax"),
                },
                includeTips
                    ? {
                          value: formatToLocalStr("total_tips"),
                      }
                    : null,
                {
                    value: formatToLocalStr("dlv_fee"),
                },
            ],
            bottomBorder: "border-line-solid",
        });
        return rows;
    }

    function createStoreDetail(rows: any) {
        const { reportData } = props;
        const sales = reportData["sales"];
        if (sales)
            Object.keys(sales).forEach((date: any) => {
                rows.push({
                    topHeaderText: { class: "report-date-text", value: date },
                });
                createStaffDetail(date, rows);
                createStoreDailySubtotal(date, rows);
                createStoreDailySummary(date, rows);
            });
        return rows;
    }

    function createStaffDetail(date: any, rows: any) {
        const { reportData } = props;
        const sales = reportData["sales"];
        const staff = sales[date]["staff"];
        if (staff)
            staff.forEach((staff: any) => {
                createStaffSummaryDetail(rows, staff);
                createStaffOrderDetail(rows, staff);
            });
        return rows;
    }

    function createStaffSummaryDetail(rows: any, staff: any) {
        const { includeTips, includeOrderDetail } = props;
        const isStaffRp = isStaffReport();
        const staffSummary = staff["staff_daily_summary"];
        rows.push({
            col: [
                isStaffRp || includeOrderDetail
                    ? {
                          class: "bold larger-text text-align-start",
                          value: getFirstColumnStr(staff.name),
                      }
                    : null,
                isStaffRp
                    ? {
                          class: "bold",
                          value: staffSummary.total_cnt,
                      }
                    : null,
                isStaffRp
                    ? {
                          class: "bold",
                          value: formatCurrency(staffSummary.total_sales ?? 0, props.storeCurrency),
                      }
                    : null,
                isStaffRp
                    ? {
                          value: formatCurrency(staffSummary.tax ?? 0, props.storeCurrency),
                      }
                    : null,
                isStaffRp && includeTips
                    ? {
                          class: "bold",
                          value: includeTips ? formatCurrency(staffSummary.tips ?? 0, props.storeCurrency) : "",
                      }
                    : null,
                isStaffRp
                    ? {
                          class: "bold",
                          value: formatCurrency(staffSummary.dlv_fee ?? 0, props.storeCurrency),
                      }
                    : null,
            ],
            bottomBorder: "border-line-solid",
        });
        return rows;
    }

    function createStaffOrderDetail(rows: any, staff: any) {
        const { includeOrderDetail, includeTips } = props;
        if (includeOrderDetail) {
            rows.push({
                col: [
                    {
                        class: "text-align-start",
                        value: formatToLocalStr("order_id"),
                    },
                    {
                        value: formatToLocalStr("time"),
                    },
                    {
                        value: formatToLocalStr("CD"),
                    },
                    {
                        value: formatToLocalStr("amt"),
                    },
                    {
                        value: formatToLocalStr("tax"),
                    },
                    includeTips
                        ? {
                              value: formatToLocalStr("tips"),
                          }
                        : null,
                    {
                        value: formatToLocalStr("dlv_fee"),
                    },
                ],
                bottomBorder: "border-line-dashed",
            });
            staff["orders"].forEach((order: any) => {
                rows.push({
                    col: [
                        {
                            class: "text-align-start",
                            value: order["id"],
                        },
                        {
                            value: moment(order["time"]).format(TIME_FORMAT),
                        },
                        {
                            value: formatToPaymentCode(order["pay_mtd"]),
                        },
                        {
                            value: formatCurrency(order["amt"] ?? 0, props.storeCurrency),
                        },
                        {
                            value: formatCurrency(order["tax"] ?? 0, props.storeCurrency),
                        },
                        includeTips
                            ? {
                                  value: formatCurrency(order["tips"] ?? 0, props.storeCurrency),
                              }
                            : null,
                        {
                            value: formatCurrency(order["dlv_fee"] ?? 0, props.storeCurrency),
                        },
                    ],
                });
            });
        }
    }

    function createStoreDailySubtotal(date: any, rows: any) {
        const { includeTips, reportData } = props;
        const sales = reportData["sales"];
        const storeSubtotal = sales[date]["subtotal"];
        if (!storeSubtotal) return rows;
        storeSubtotal.forEach((paymentSubtotal: any) => {
            rows.push({
                topBorder: "border-line-solid",
                col: [
                    {
                        value: formatToPaymentLocal(paymentSubtotal["pay_mtd"]),
                    },
                    {
                        value: paymentSubtotal["total_cnt"],
                    },
                    {
                        value: formatCurrency(paymentSubtotal["total_sales"] ?? 0, props.storeCurrency),
                    },
                    {
                        value: formatCurrency(paymentSubtotal["tax"] ?? 0, props.storeCurrency),
                    },
                    includeTips
                        ? {
                              value: formatCurrency(paymentSubtotal["tips"] ?? 0, props.storeCurrency),
                          }
                        : null,
                    {
                        value: formatCurrency(paymentSubtotal["dlv_fee"] ?? 0, props.storeCurrency),
                    },
                ],
                bottomBorder: "border-line-solid",
            });
        });

        return rows;
    }

    function createStoreDailySummary(date: any, rows: any) {
        const { includeTips, reportData } = props;
        const sales = reportData["sales"];
        const storeSummary = sales[date]["store_daily_summary"];
        const taxes = _.get(sales, `${date}.store_daily_summary.taxes`, {});
        rows.push({
            topBorder: "border-line-solid",
            col: [
                {
                    value: getFirstColumnStr(formatToLocalStr("daily_summary")),
                },
                {
                    value: storeSummary["total_cnt"],
                },
                {
                    value: formatCurrency(storeSummary["total_sales"] ?? 0, props.storeCurrency),
                },
                {
                    value: formatCurrency(storeSummary["tax"] ?? 0, props.storeCurrency),
                },
                includeTips
                    ? {
                          value: formatCurrency(storeSummary["tips"] ?? 0, props.storeCurrency),
                      }
                    : null,
                {
                    value: formatCurrency(storeSummary["dlv_fee"] ?? 0, props.storeCurrency),
                },
            ],
            bottomBorder: "border-line-double",
        });
        if (!_.isEmpty(taxes)) {
            rows = createStoreTaxDetail(rows, taxes);
        }
        return rows;
    }

    function createStoreTaxDetail(rows: any, taxes: any) {
        rows.push({
            topBorder: "border-line-solid",
            topHeaderText: { class: "sales-report-tax-title", value: formatToLocalStr("tax_details") },
            bottomBorder: "border-line-solid",
        });
        let total = 0;
        Object.keys(taxes).forEach((key) => {
            const value = taxes[key];
            rows.push({
                col: [
                    {
                        value: key,
                    },
                    {
                        value: formatCurrency(value ?? 0, props.storeCurrency),
                    },
                ],
            });
            total += value;
        });
        rows.push({
            topBorder: "border-line-solid",
            col: [
                {
                    value: formatToLocalStr("tax_total"),
                },
                {
                    value: formatCurrency(total ?? 0, props.storeCurrency),
                },
            ],
            bottomBorder: "border-line-double",
        });
        return rows;
    }

    function createStoreSummaryTitle(rows: any) {
        const { reportData } = props;
        const { formatMessage } = intl;

        const startDate = reportData["start_dt"].split(" ")[0];
        const endDate = reportData["end_dt"].split(" ")[0];

        const summaryTitle =
            formatMessage({ id: "sales_summary" }) +
            " " +
            formatMessage({ id: "from" }) +
            " " +
            startDate +
            " " +
            formatMessage({ id: "to" }) +
            " " +
            endDate;

        rows.push({
            topBorder: "border-line-solid",
            col: [
                {
                    class: "text-align-center bold",
                    value: summaryTitle,
                },
            ],
            bottomBorder: "border-line-none",
        });
        return rows;
    }

    function createStoreSubtotal(rows: any) {
        const { includeTips, reportData } = props;
        const reportSubtotal = reportData["subtotal"];
        if (!reportSubtotal) return rows;

        reportSubtotal.forEach((paymentSubtotal: any) => {
            rows.push({
                topBorder: "border-line-none",
                col: [
                    {
                        value: formatToPaymentLocal(paymentSubtotal["pay_mtd"]),
                    },
                    {
                        value: paymentSubtotal["total_cnt"],
                    },
                    {
                        value: formatCurrency(paymentSubtotal["total_sales"] ?? 0, props.storeCurrency),
                    },
                    {
                        value: formatCurrency(paymentSubtotal["tax"] ?? 0, props.storeCurrency),
                    },
                    includeTips
                        ? {
                              value: formatCurrency(paymentSubtotal["tips"] ?? 0, props.storeCurrency),
                          }
                        : null,
                    {
                        value: formatCurrency(paymentSubtotal["dlv_fee"] ?? 0, props.storeCurrency),
                    },
                ],
                bottomBorder: "border-line-solid",
            });
        });
        return rows;
    }

    function createStoreTotal(rows: any) {
        const { includeTips, reportData } = props;
        const reportTotal = reportData["total"];
        const taxes = _.get(reportData, "total.taxes", {});
        rows.push({
            topBorder: "border-line-solid",
            col: [
                {
                    class: "bold",
                    value: getFirstColumnStr(formatToLocalStr("period_summary")),
                },
                {
                    class: "bold",
                    value: reportTotal["total_cnt"],
                },
                {
                    class: "bold",
                    value: formatCurrency(reportTotal["total_sales"] ?? 0, props.storeCurrency),
                },
                {
                    value: formatCurrency(reportTotal["tax"] ?? 0, props.storeCurrency),
                },
                includeTips
                    ? {
                          class: "bold",
                          value: formatCurrency(reportTotal["tips"] ?? 0, props.storeCurrency),
                      }
                    : null,
                {
                    value: formatCurrency(reportTotal["dlv_fee"] ?? 0, props.storeCurrency),
                },
            ],
            bottomBorder: "border-line-solid",
        });
        if (!_.isEmpty(taxes)) {
            rows = createStoreTaxDetail(rows, taxes);
        }
        return rows;
    }

    function getClassName(j: any, arr: any) {
        const { includeTips } = props;
        if (arr.length === 2) {
            return j % 2 === 0 ? "col-6 text-align-start" : "col-8 text-align-end";
        }
        if (arr.length === 5) {
            return j % 5 === 0
                ? "col-5xxx text-align-start"
                : j % 5 === 1
                ? "col-2 text-align-end"
                : j % 5 === 2
                ? "col-6 text-align-end"
                : j % 5 === 3
                ? "col-6 text-align-end"
                : j % 5 === 4
                ? "col-6 text-align-end"
                : "col-6 text-align-end";
        }
        if (arr.length === 6) {
            return includeTips
                ? j % 6 === 0
                    ? "col-5xx text-align-start"
                    : j % 6 === 1
                    ? "col-2 text-align-end"
                    : j % 6 === 2
                    ? "col-4xx text-align-end"
                    : j % 6 === 3
                    ? "col-3xx text-align-end"
                    : j % 6 === 4
                    ? "col-3xx text-align-end"
                    : j % 6 === 5
                    ? "col-3xx text-align-end"
                    : `col-6 text-align-end`
                : j % 6 === 0
                ? "col-4 text-align-start"
                : j % 6 === 1
                ? "col-3x text-align-end"
                : j % 6 === 2
                ? "col-2 text-align-end"
                : j % 6 === 3
                ? "col-5 text-align-end"
                : j % 6 === 4
                ? "col-5 text-align-end"
                : j % 6 === 5
                ? "col-5 text-align-end"
                : `col-6 text-align-end`;
        }
        if (arr.length === 7) {
            return j % 7 === 0
                ? "col-4 text-align-start"
                : j % 7 === 1
                ? "col-3x text-align-end"
                : j % 7 === 2
                ? "col-2 text-align-end"
                : j % 7 === 3
                ? "col-4x text-align-end"
                : j % 7 === 4
                ? "col-3x text-align-end"
                : j % 7 === 5
                ? "col-3x text-align-end"
                : j % 7 === 6
                ? "col-3x text-align-end"
                : `col-6 text-align-end`;
        }
        return `col-24 text-align-start`;
    }

    const createContent = () => {
        const { reportData } = props;
        if (!reportData) return;
        let rows: any = [];
        rows = createTopHeader(rows);
        rows = createStoreDetail(rows);
        rows = createStoreSummaryTitle(rows);
        rows = createStoreSubtotal(rows);
        rows = createStoreTotal(rows);

        const rowsClean = _helper.clean(rows);
        return rowsClean;
    };

    const { rpTitle, reportData } = props;

    const startDate = reportData["start_dt"];
    const endDate = reportData["end_dt"];

    return (
        <div className="sales-operation-sales-report" id="sales-operation-sales-report">
            <div className="header-report">{rpTitle}</div>
            <Row className="item-report">
                <Col span={3}>
                    <FormattedMessage id="from" />
                </Col>
                <Col span={4} className="bold">
                    {startDate.split(" ")[0]}
                </Col>
                <Col span={3}>
                    <FormattedMessage id="to" />
                </Col>
                <Col span={4} className="bold">
                    {endDate.split(" ")[0]}
                </Col>
            </Row>
            {createContent().map((row: any, i: any) => (
                <div key={i}>
                    {row.topHeaderText ? <div className={row.topHeaderText.class}>{row.topHeaderText.value}</div> : ""}
                    <div className={row.topBorder}></div>
                    <div className="row">
                        {row.col
                            ? row.col.map((col: any, j: any, arr: any) => (
                                  <div className={getClassName(j, arr)} key={j}>
                                      <div className={col.class}>{col.value}</div>
                                  </div>
                              ))
                            : null}
                    </div>
                    <div className={row.bottomBorder}></div>
                </div>
            ))}
            <div className="text-align-end">{moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}</div>
        </div>
    );
}

export default SalesOperationReport;
