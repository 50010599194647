import _ from "lodash";
export enum countries {
    ALL = "0",
    SPECIFIC = "1",
}

export enum orAnd {
    OR = "0",
    AND = "1",
}

export enum handleType {
    PER_ORDER = "0",
    PER_ITEM = "1",
}

export enum handleCalcs {
    FIXED = "0",
    PERCENTAGE = "1",
}

export enum destinationTypes {
    COMMERCIAL = "0",
    RESIDENTIAL = "1",
}

export enum packageTypes {
    DIVIDE_EQUAL = "00",
    ORIGIN_WEIGHT = "01",
}

const DELETE = 1;

const DEFAULT_VALUES: any = {
    // c2cSID: false,
    // c2cSnm: false,
    // c2cRegShip: false,
    // c2cExpShip: false,

    tableEnable: "0",
    tableRules: [],
    tableShipCountryType: countries.ALL,
    tableAllowCountry: [],

    // flatEnable: false,
    // flatHandleType: false,
    // flatPrice: false,
    // flatCalcHandlingFee: false,
    // flatHandlingFee: false,
    // flatShipCountryType: false,
    // flatAllowCountry: false,

    // freeEnable: false,
    // freeMinOrderAmount: false,
    // freeShipCountryType: false,
    // freeAllowCountry: false,

    // upsEnable: false,
    // upsUserID: false,
    // upsAccessKey: false,
    // upsPassword: false,
    // upsPackageRequestType: false,
    // upsContainer: false,
    // upsDestinationType: false,
    // upsWeightUnit: false,
    // upsPickupMethod: false,
    // upsMaxWeight: false,
    // upsMinWeight: false,
    // upsHandlingFee: false,
    // upsHandlingApply: false,
    // upsCalcHandlingFee: false,
    // upsAllowMethods: false,

    // fedexEnable: false,
    // fedexAccountID: false,
    // fedexMeterNumber: false,
    // fedexKey: false,
    // fedexPassword: false,
    // fedexPackaging: false,
    // fedexDropoff: false,
    // fedexWeightUnit: false,
    // fedexMaxWeight: false,
    // fedexHandlingFee: false,
    // fedexHandlingApply: false,
    // fedexCalcHandlingFee: false,
    // fedexAllowMethods: false,
    // fedexResidential: false,
    // fedexAllowCountry: false,
    // fedexShipApplicableCntry: false,
};
export const getShippingWithDefault = (payload: any={}): void => {
    const newDefaultValue: any = {};
    Object.keys(DEFAULT_VALUES).forEach((key) => {
        const value = _.get(payload, key);
        if (value === undefined || value === null) {
            newDefaultValue[key] = DEFAULT_VALUES[key];
        }
    });

    return newDefaultValue;
};

export default {
    orAnd,
    DELETE,
    countries,
    handleType,
    handleCalcs,
    destinationTypes,
    packageTypes,
};
