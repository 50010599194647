import {
    getDeliveryStatus,
    getShortDeliveryStatus,
    getOrderStatus,
    getRemainTime,
    isOnlinePay,
    getShippingMethodNumber,
    getDisplayTotal,
} from "./helper";
import { getStoreWithGid, getStoreTimeZone, isRestaurant } from "pages/liveorder/helper";
import _ from "lodash";
import helper, { getKeyByValue, getNumberFromLocalStorage } from "util/helper";
import config from "config";
import moment from "moment";
import images from "images/";
import { DEFAULT_PAGE_SIZE, DINE_IN, IN_STORE } from "app/constants";
import { ASAP } from "app/constants";
//order tabel info

export const getStatus = (order) => {
    return String(_.get(order, "ord_st"));
};

export const getOrderId = (order) => {
    return _.get(order, "ord_id") ? String(_.get(order, "ord_id")) : "";
};

export const getCustomerName = (order) => {
    return _.get(order, "c_name") ? String(_.get(order, "c_name")) : "";
};

export const getCustomerPhone = (order) => {
    return _.get(order, "c_tn") ? String(_.get(order, "c_tn")) : "";
};

export const getCreditCardTypeStr = (order) => {
    const cc = _.get(order, "cc", {});
    const creditCardTypeStr = config.CREDIT_CARD_TYPE_MAP[cc.type];
    return creditCardTypeStr ?? "credit_card";
};

export const isSameOrder = (order, otherOrder) => {
    return getOrderId(order) === getOrderId(otherOrder);
};

export const findOrderInListAndPerformAction = (orderList, selectedOrder, doFound, doMissing) => {
    let index = orderList.findIndex((order) => {
        return isSameOrder(order, selectedOrder);
    });
    if (index === -1 && doMissing) {
        doMissing();
    } else if (doFound) {
        doFound(index);
    }
};

const SHOW_ORDER_STATUS_STATUSES = ["3", "4", "5", "6", "7"];
export const getOrderMainStatusText = (order, store) => {
    const orderStatus = getStatus(order);
    const deliveryStatusText = getShortDeliveryStatus(order, store);
    const orderStatusText = getOrderStatus(order);
    const COLOR_MAP = {};
    COLOR_MAP[Number(config.ORDER_STATUS_MAPPING_TO_NUMERIC.canceled)] = "#d50a00";
    return {
        text: SHOW_ORDER_STATUS_STATUSES.includes(orderStatus)
            ? orderStatusText
            : deliveryStatusText
            ? `status_${deliveryStatusText}`
            : orderStatusText
            ? orderStatusText
            : " ",
        color: COLOR_MAP[orderStatus] ? COLOR_MAP[orderStatus] : "black",
        status: orderStatus,
    };
};

export const getOrderSubStatusText = (order, store) => {
    const orderStatus = getStatus(order); //order list, order detail
    if (SHOW_ORDER_STATUS_STATUSES.includes(orderStatus)) {
        return "";
    }
    return getDeliveryStatus(order, store);
};

export const showStoreName = (stores) => {
    return stores && stores.length > 1;
};

export const getStore = (order, stores) => {
    const storesData = Array.isArray(stores) ? stores : [];
    const orderGid = _.get(order, "g_id", "");
    return getStoreWithGid(orderGid, storesData);
};

export const getStoreName = (store, lan = "en") => {
    const transObj = _.get(store, "general_info.store_nm", {});
    return helper.getTransString(transObj, lan);
};

export const getOrderType = (order, store) => {
    const storeFlg = _.get(store, "store_flg", "");
    const storeType = String(helper.getRealStoreType(storeFlg));
    const shippingMethod = getShippingMethodNumber(order);
    const realMethod = helper.reviseDeliveryMethod(storeType, config.SHIPPING_MAPPING_TO_TEXT[shippingMethod]);
    return realMethod;
};

export const getOrderTableOriginal = (order) => {
    const shippingMethod = getShippingMethodNumber(order);
    const table = _.get(order, "tb_num") ? _.get(order, "tb_num") : "";
    const withTable = (table || Number(table) === 0) && helper.isEatIn(shippingMethod);
    return withTable ? String(table) : "";
};

export const getOrderTable = (order, store) => {
    const table = getOrderTableOriginal(order);
    const withTable = isRestaurant(store) && table;
    return withTable ? `#${table}` : "";
};

export const getOrderTotal = (order) => {
    return _.get(order, "ord_ttl", 0);
};

export const getOrderTotalStr = (order, store) => {
    const total = getDisplayTotal(order);
    const currencty = _.get(store, "general_info.currency", "");
    return helper.getCurrencyFormattedString(total, currencty);
};

export const getPaymentMethod = (order) => {
    return String(_.get(order, "pay_mtd", 0));
};

export const getPaymentMethodStr = (order) => {
    const paymentMethod = getPaymentMethod(order);
    return config.PAYMENT_METHOD_MAPPING[paymentMethod] ? config.PAYMENT_METHOD_MAPPING[paymentMethod] : " ";
};

export const isOrderPaid = (order) => {
    return _.get(order, "pay_status") === config.PAY_STATUS_TO_NUMERIC.paid;
};

export const getOrderTime = (order, store) => {
    const time = _.get(order, "ord_dt");
    return helper.utcToLocal(time, {
        type: "shortest",
        timeZone: getStoreTimeZone(store),
        date: false,
    });
};

export const getDueTimeInfo = (order, store) => {
    const ETA = _.get(order, "est_dt");
    const expecteTime = _.get(order, "exp_dt_start");
    const time = ETA ? ETA : expecteTime;
    const timeZone = getStoreTimeZone(store);
    const now = moment().tz(timeZone);
    const eta = moment(time);
    const timeStr = helper.isString(time) ? time : "";

    const isASAP = () => {
        return !eta.isValid() || timeStr.includes("2000-01-01");
    };

    const showStatus = () => {
        const orderStatus = getStatus(order);
        return SHOW_ORDER_STATUS_STATUSES.includes(orderStatus);
    };

    const labelStr = () => {
        return isASAP() || showStatus()
            ? ""
            : eta.isValid() && now.isAfter(time)
            ? "over_due"
            : ETA
            ? "exp_time_stort"
            : "req_time_short";
    };

    const remainTime = () => {
        if (showStatus()) {
            return " ";
        }
        if (isASAP()) {
            return ASAP;
        }
        return getRemainTime(timeStr, store);
    };
    return {
        "sub-info": labelStr(),
        "main-info": remainTime() || " ",
        "show-red": labelStr() === "over_due",
    };
};

export const getPaymentIcon = (order) => {
    let paymentStr = getPaymentMethodStr(order);
    const isOnlinePaymentMethod = isOnlinePay(order);
    const isPaid = isOrderPaid(order);
    const PAYMENT_MAP = config.PAYMENT_MAPPING_TO_NUMBERIC;

    const CONSIDERED_ONLINE_PAYMENTS = [
        getKeyByValue(PAYMENT_MAP, PAYMENT_MAP.credit_card),
        getKeyByValue(PAYMENT_MAP, PAYMENT_MAP.wechat_pay),
        getKeyByValue(PAYMENT_MAP, PAYMENT_MAP.alipay),
        getKeyByValue(PAYMENT_MAP, PAYMENT_MAP.paypal_express),
        getKeyByValue(PAYMENT_MAP, PAYMENT_MAP.union_pay),
        getKeyByValue(PAYMENT_MAP, PAYMENT_MAP.union_pay_express),
    ];

    const CONSIDERED_OFFLINE_PAYMENTS = [
        getKeyByValue(PAYMENT_MAP, PAYMENT_MAP.cash),
        getKeyByValue(PAYMENT_MAP, PAYMENT_MAP.etransfer),
        getKeyByValue(PAYMENT_MAP, PAYMENT_MAP.payment_type_kiosk),
    ];

    if (
        ((CONSIDERED_ONLINE_PAYMENTS.includes(paymentStr) && !isOnlinePaymentMethod) ||
            CONSIDERED_OFFLINE_PAYMENTS.includes(paymentStr)) &&
        !isPaid
    ) {
        paymentStr += "-grey";
    }

    // check for the credit card icon. ppcp without 3ds: paypal_complete_payments, ppcp with 3ds: paypal_complete_payments_paypal_with_3ds)
    const icon =
        paymentStr == "credit_card" ||
        paymentStr == "braintree_credit_card" ||
        paymentStr == "paypal_complete_payments" ||
        paymentStr == "paypal_complete_payments_paypal_with_3ds"
            ? images[`icon_${getCreditCardTypeStr(order)}`]?.default
            : images[`icon_${paymentStr}`]?.default;
    return icon ? icon : images[`icon_${getKeyByValue(PAYMENT_MAP, PAYMENT_MAP.pay_later)}`]?.default;
};

export const FILTER_KEYS = {
    status: "status",
    type: "s_method",
    name_number: "quick_search",
    start_date: "s_date",
    end_date: "e_date",
    date_format: "YYYYMMDD",
    order_id: "ord_id",
    phone_number: "phone",
    first_name: "fnm",
    last_name: "lnm",
    max_amount: "max_amt",
    min_amount: "min_amt",
    lan: "lan",
    sku_prefix: "sku_prefix",
    payment: "p_method",
    export_type: "type",
    customer_id: "customer_id",
    return_type: "return_fmt",
    export_order_id: "ord_id",
    group_purchase_ids: "gp_ids",
};

export const FILER_DATES_OPTIONS = {
    last_seven_days: {
        s_date: moment().subtract(7, "days").format(FILTER_KEYS.date_format),
        e_date: moment().format(FILTER_KEYS.date_format),
    },
    last_fourteen_days: {
        s_date: moment().subtract(14, "days").format(FILTER_KEYS.date_format),
        e_date: moment().format(FILTER_KEYS.date_format),
    },
    last_thirty_days: {
        s_date: moment().subtract(30, "days").format(FILTER_KEYS.date_format),
        e_date: moment().format(FILTER_KEYS.date_format),
    },
    this_week: {
        s_date: moment().startOf("week").format(FILTER_KEYS.date_format),
        e_date: moment().format(FILTER_KEYS.date_format),
    },
    this_month: {
        s_date: moment().startOf("month").format(FILTER_KEYS.date_format),
        e_date: moment().format(FILTER_KEYS.date_format),
    },
    last_week: {
        s_date: moment().subtract(1, "week").startOf("week").format(FILTER_KEYS.date_format),
        e_date: moment().subtract(1, "week").endOf("week").format(FILTER_KEYS.date_format),
    },
    last_two_weeks: {
        s_date: moment().subtract(2, "week").startOf("week").format(FILTER_KEYS.date_format),
        e_date: moment().subtract(2, "week").endOf("week").format(FILTER_KEYS.date_format),
    },
    last_month: {
        s_date: moment().subtract(1, "month").startOf("month").format(FILTER_KEYS.date_format),
        e_date: moment().subtract(1, "month").endOf("month").format(FILTER_KEYS.date_format),
    },
    this_year: {
        s_date: moment().startOf("year").format(FILTER_KEYS.date_format),
        e_date: moment().format(FILTER_KEYS.date_format),
    },
    last_365_days: {
        s_date: moment().subtract(365, "days").format(FILTER_KEYS.date_format),
        e_date: moment().format(FILTER_KEYS.date_format),
    },
    custom: "custom",
};

export const FILTER_EXPORT_TYPE_MAPPING = {
    order_detail_per_item: "1",
    order_detail_per_order: "4",
    item_detail: "2",
    item_summary: "3",
    all_in_one_report_excel: "5",
};

export const SUMMARY_KEY_MAPPING = {
    type: "summary_by_shpmtd",
    status: "summary_by_state",
    payment: "summary_by_paymtd",
};

export const KEY_TYPE_MAPPING = {
    [FILTER_KEYS.status]: "status",
    [FILTER_KEYS.type]: "type",
    [FILTER_KEYS.payment]: "payment",
};

export const FILTER_RETURN_TYPE_MAPPING = {
    csv: 2,
    json: 1,
};

export const EXPORT_DEFAULT_VALUES = {
    [FILTER_KEYS.return_type]: FILTER_RETURN_TYPE_MAPPING.csv,
    [FILTER_KEYS.export_type]: FILTER_EXPORT_TYPE_MAPPING.all_in_one_report_excel,
};

export const getSavedOrderHistoryPageSize = () => {
    return getNumberFromLocalStorage(config.LOCAL_STORAGE_KEYS.order_history_page_size) ?? DEFAULT_PAGE_SIZE;
};

export const getDefaultFilters = () => ({
    paging: {
        page: 1,
        limit: getSavedOrderHistoryPageSize(),
    },
    [FILTER_KEYS.status]: getAllOptionsValue("status"), //all
    [FILTER_KEYS.type]: getAllOptionsValue("type"), //all
    [FILTER_KEYS.start_date]: moment().subtract(30, "days").format(FILTER_KEYS.date_format), //30 days from now
    [FILTER_KEYS.end_date]: moment().format(FILTER_KEYS.date_format),
    [FILTER_KEYS.payment]: getAllOptionsValue("payment"),
    [FILTER_KEYS.export_type]: FILTER_EXPORT_TYPE_MAPPING.all_in_one_report_excel,
    [FILTER_KEYS.return_type]: FILTER_RETURN_TYPE_MAPPING.csv,
    [FILTER_KEYS.group_purchase_ids]: [],
});

export const getFilterDayLabel = (startDate, endDay) => {
    let index = Object.values(FILER_DATES_OPTIONS).findIndex((days) => {
        return String(days.s_date) === String(startDate) && String(days.e_date) === String(endDay);
    });
    return index >= 0 ? Object.keys(FILER_DATES_OPTIONS)[index] : "";
};

export const getStatusOptions = (state, store) => {
    const categoriesMap = _.cloneDeep(config.ORDER_LIST_SHORTEN_STATUS_MAPPING);
    return getOptions(categoriesMap, state, "status", store);
};

export const getTypeOptions = (state, store) => {
    const categoriesMap = _.cloneDeep(config.SHIPPING_MAPPING_TO_NUMERIC);
    return getOptions(categoriesMap, state, "type", store);
};

export const getPaymentOptions = (state) => {
    const categoriesMap = _.cloneDeep(config.PAYMENT_FILTER_MAPPING);
    return getOptions(categoriesMap, state, "payment");
};

export const getOptions = (categoriesMap, state, type, store) => {
    let options = [],
        allValues = [],
        allAmt = 0;
    Object.keys(categoriesMap).forEach((status) => {
        const value = categoriesMap[status];
        const getSummaryKey = SUMMARY_KEY_MAPPING[type];
        const total = _.get(state, `summary.${getSummaryKey}.${value}`, 0);
        allAmt += total;
        allValues.push(value);
        let label = status;
        const storeFlags = _.get(store, "store_flg", "");
        if (status === "serve") {
            label = helper.isRestaurantFromFlags(storeFlags) ? "serve_restaurant" : "serve_others";
        }
        if (status === "eatin") {
            label = helper.isRestaurantFromFlags(storeFlags) ? DINE_IN : IN_STORE;
        }
        options.push({
            label: label,
            value: value,
            total: total,
        });
    });
    options.unshift({
        label: "all",
        value: allValues,
        total: allAmt,
    });
    return options;
};

export const getOptionValue = (obj, type, filter, style = "normal", store) => {
    const isAll = String(obj.label) === "all";
    const value = obj.value;
    filter = Array.isArray(filter) ? filter : [];
    let searchValue = [];
    const selectAll = _.isEqual(filter, getAllOptionsValue(type));
    if (isAll && _.isEqual(filter, value)) {
        //all, all is checked
        searchValue = [];
    } else if (isAll && !_.isEqual(filter, value)) {
        //all, all is not checked
        searchValue = getAllOptionsValue(type);
    } else if (!isAll && filter.includes(value) && (style === "normal" || !selectAll)) {
        //single status, status is check
        const index = _.findIndex(filter, (v) => v === value);
        searchValue = _.cloneDeep(filter);
        searchValue.splice(index, 1);
    } else if (!isAll && filter.includes(value) && style === "button" && selectAll) {
        //single status, status is check
        searchValue = [value];
    } else if (!isAll && !filter.includes(value)) {
        //single status, status is not check
        let filterCopy = _.cloneDeep(filter);
        filterCopy.push(value);
        const options = getOptionsWithType(type, store);
        // sort according to options
        options.forEach((op) => {
            const opValue = op.value;
            const opStatus = op.label;
            if (filterCopy.includes(opValue) && String(opStatus) !== "all") {
                searchValue.push(opValue);
            }
        });
    }
    return searchValue;
};

export const getOptionsWithType = (type, state, store) => {
    let options = [];
    if (String(type) === "status") {
        options = getStatusOptions(state, store);
    }
    if (String(type) === "type") {
        options = getTypeOptions(state, store);
    }
    if (String(type) === "payment") {
        options = getPaymentOptions(state);
    }
    return options;
};

export const getAllOptionsValue = (type) => {
    let options = getOptionsWithType(type);
    const allOption = options.find((opt) => String(opt.label) === "all");
    return _.get(allOption, "value");
};

export const sortFilterSearchValues = (filters) => {
    let result = {};
    const possibleArrayKeys = Object.keys(KEY_TYPE_MAPPING);
    const shouldNotFetchKey = Object.keys(filters).find((key) => {
        const value = filters[key];
        if (possibleArrayKeys.includes(key)) {
            const type = KEY_TYPE_MAPPING[key];
            if (_.isEqual(value, getAllOptionsValue(type))) {
                result[key] = [];
            } else {
                result[key] = value;
            }
            return _.isEmpty(value);
        } else if (value) {
            result[key] = value;
        }
        return false;
    });
    return shouldNotFetchKey ? false : result;
};
